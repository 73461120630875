import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IBlockedChoice, IContext, IModifier, IProduct } from "@crunchit/types";

import useActiveModifier from "./useActiveModifier";
import useModifiers from "./useModifiers";

import Modifier from "./Modifier";
import SimpleTotal from "components/basket/totals/SimpleTotal";

import "./Modifiers.scss";

interface IModifierListProps {
  product: IProduct;
  context: IContext;
  blockedChoices: IBlockedChoice[];
  isCollapsible: boolean;

  dismiss: () => void;
}

export default function Modifiers(props: IModifierListProps) {
  // Wrapper for all productModifiers, to keep track of choices, max and min, etc.
  const { product, context, blockedChoices, isCollapsible } = props;
  const { dismiss } = props;

  const { t } = useTranslation();

  const { activeModifierIndex, updateActiveModifierIndex, collapseModifier, expandModifier } = useActiveModifier();

  const { getModifiers, getProductModifier, selectedChoices, updateChoiceCount, modifierHasWarning, getTotalPriceForModifiers, finalize, showFinalizeWarning, reset } = useModifiers(product, context);

  const modifiers = useMemo(() => getModifiers(blockedChoices), [getModifiers, blockedChoices]);
  const totalPrice = useMemo(() => getTotalPriceForModifiers(modifiers), [getTotalPriceForModifiers, modifiers]);

  const handleChoiceCountUpdate = (choiceId: number, modifier: IModifier, newCount: number) => {
    const continueToNextModifier = updateChoiceCount(choiceId, modifier, newCount);
    if (continueToNextModifier) {
      updateActiveModifierIndex(activeModifierIndex + 1, modifier.id);
    }
  };

  const handleFinalizeClick = () => {
    const success = finalize(modifiers);
    if (success) {
      dismiss();
      reset();
    }
  };

  return (
    <div className="product-modifier-container">
      {modifiers.map(function (modifier, index) {
        const productModifier = getProductModifier(modifier.id);
        return !productModifier ? null : <Modifier key={index} modifierIndex={index + 1} isExpanded={!isCollapsible || activeModifierIndex === index} isCollapsible={isCollapsible} modifier={modifier} productModifier={productModifier} choices={context.choices} selectedChoices={selectedChoices[modifier.id]} modifierHasWarning={modifierHasWarning} handleChoiceCountUpdate={handleChoiceCountUpdate} dismiss={collapseModifier} expand={() => expandModifier(index)} />;
      })}

      <div className="product-modifier-footer">
        <SimpleTotal total={totalPrice} />

        {showFinalizeWarning && <p className="missing-choices-warning">{t("errors:Products.ModifierChoicesMissing")}</p>}

        <button className="button action-button continue-button" onClick={handleFinalizeClick}>
          {t("products:AddToCart")}
        </button>
      </div>
    </div>
  );
}
