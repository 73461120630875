import { siteStorageName } from "@crunchit/types";
import { saveToLocalStorage } from "@crunchit/utilities";
import { Navigate, useParams } from "react-router-dom";
import { tableNumberKey } from "utils/constants";

export default function Room() {
  const { tableNumber } = useParams();

  if (!tableNumber || tableNumber.includes(" ")) {
    return <Navigate to="/error" />;
  }

  saveToLocalStorage(siteStorageName, tableNumberKey, tableNumber);

  return <Navigate to="/" />;
}
