import { IBlockedProduct, ICategory, IProduct } from "@crunchit/types";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import PageTitle from "components/ui/PageTitle";
import Sidebar from "components/navigation/Sidebar";
import Product from "components/menu/Product";

import useProducts from "hooks/useProducts";
import useTranslationText from "hooks/useTranslationText";

export default function Category() {
  const { appIsInitialized } = useAppSelector();
  const { categories, isLoading: menuDataIsLoading } = useMenuSelector();
  const { categoryId } = useParams();
  const { getTranslationText } = useTranslationText();

  let { context, blockedProducts, blockedChoices, labels, loadProducts, loadWarehouse, loadLabels } = useProducts();

  const category = useMemo(() => (categoryId ? categories.find((c: ICategory) => c.id === Number(categoryId)) : undefined), [categoryId, categories]);

  useEffect(() => {
    if (appIsInitialized) {
      loadWarehouse();
      loadLabels();
    }
  }, [appIsInitialized, loadLabels, loadWarehouse]);

  useEffect(() => {
    loadProducts(category ? category.products : []);
  }, [category, loadProducts]);

  return (
    <>
      {category !== undefined && (
        <>
          <PageTitle title={getTranslationText(category.name)} subtitle={getTranslationText(category.description)} />

          <div className="content-body sidebar-body">
            <Sidebar />

            <div className="product-list">
              {!menuDataIsLoading &&
                context.products.map(function (product: IProduct) {
                  return <Product key={product.id} product={product} context={context} labels={labels} isBlockedProduct={blockedProducts.find((b: IBlockedProduct) => b.productId === product.id) !== undefined} blockedChoices={blockedChoices} />;
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
