import { useState, useCallback, ReactNode } from "react";

import "./SectionWrapper.scss";

interface ISectionWrapperProps {
  index?: number;
  title?: string;
  children: ReactNode;
}

// Wrapper component for collapsible sections in checkout
export default function SectionWrapper(props: ISectionWrapperProps) {
  const { index, title, children } = props;

  let [isExpanded, setIsExpanded] = useState(true);

  const indexText = index !== undefined ? `0${index} ` : "";

  let handleToggleClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div className={`content-section collapsible-section ${isExpanded ? "expanded" : "collapsed"}`}>
      {title !== undefined && (
        <div className="content-title-container" onClick={handleToggleClick}>
          <h3 className="content-title">
            <span className="number">{indexText}</span>
            {title}
          </h3>
          <div className="collapsible-icon"></div>
        </div>
      )}

      <div className="content-body collapsible-content">{children}</div>
    </div>
  );
}
