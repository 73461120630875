import { useTranslation } from "react-i18next";
import { ICheckoutSectionProps } from "@crunchit/types";

import { useCheckoutSelector, checkoutActions } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import SectionWrapper from "./SectionWrapper";
import "./OrderComment.scss";

export default function OrderComment(props: ICheckoutSectionProps) {
  const { index } = props;

  const { t } = useTranslation();
  const dispatch = useCustomDispatch();
  const { checkoutSession } = useCheckoutSelector();

  const { orderComment = "" } = checkoutSession.guestInformation;
  const commentMaxLength = 125;

  function handleInputChange(value: string) {
    let updatedGuestInfo = Object.assign({}, checkoutSession.guestInformation);

    if (value.length <= commentMaxLength) {
      updatedGuestInfo.orderComment = value;
      dispatch(checkoutActions.setCheckoutSessionGuestInfo(updatedGuestInfo));
    }
  }

  return (
    <SectionWrapper index={index} title={t("checkout:OrderComment")}>
      <div className="order-comment-content">
        <div className="text-area-label">
          <p className="character-count">
            {orderComment.length}/{commentMaxLength}
          </p>
        </div>

        <textarea className="order-comment" maxLength={commentMaxLength} value={orderComment} onChange={({ target: { value } }) => handleInputChange(value)}></textarea>
      </div>
    </SectionWrapper>
  );
}
