import { useMemo } from "react";
import { ICategory } from "@crunchit/types";

import { useCustomSelector } from "store/useStore";
import { useAppSelector } from "store/app";

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state.menu);

  const { categories } = state;
  const { appSettings } = useAppSelector();

  const precheckoutCategoryId = appSettings.precheckoutCategory;

  const precheckoutCategory = useMemo(() => {
    return categories.find((c: ICategory) => c.id === precheckoutCategoryId);
  }, [categories, precheckoutCategoryId]);

  const filteredCategories = useMemo(() => {
    let cats: ICategory[] = categories || [];

    // Filtering out the precheckout category
    if (precheckoutCategoryId) {
      cats = cats.filter((c) => c.id !== precheckoutCategoryId);
    }

    cats = cats.filter((c) => c.products.length > 0);

    return cats;
  }, [categories, precheckoutCategoryId]);

  return {
    ...state,
    categories: filteredCategories,
    precheckoutCategory,
  };
}
