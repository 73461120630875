const getCheckoutSettings = (isFasttrack = false) => {
  const defaultSettings = {
    terms: "https://static.crunchorder.com/terms",
    requiredGuestInformation: {
      name: true,
      phone: false,
      email: false,
    },
    enforceCapacity: true,
  };

  if (isFasttrack) {
    return {
      ...defaultSettings,
      enforceCapacity: false,
    };
  }

  return defaultSettings;
};

export default getCheckoutSettings;
