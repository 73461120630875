import { ICheckoutDiscount } from "@crunchit/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import { validateDiscountCode } from "utils/helpers/checkout/validation";

export function useDiscountValidation(discountInstanceId: string) {
  const { module } = useAppSelector();
  const { checkoutSession } = useCheckoutSelector();
  const { basketProducts, basketProductTotal } = useBasketSelector({ allowCartFees: true });

  const dispatch = useCustomDispatch();

  const previousBasketProductTotal = useRef(0); // Keeping track of the basket total changes, so we can recalculate the discount

  let [validationIsLoading, setValidationIsLoading] = useState(false);
  let [errorMessageKey, setErrorMessageKey] = useState("");

  let validatedDiscount = useMemo(() => (checkoutSession.discount ? checkoutSession.discount : {}), [checkoutSession.discount]);
  let { discountCode = "", amount = 0 } = validatedDiscount;

  const handleDiscountValidation = useCallback(
    async (discountCode: string) => {
      setValidationIsLoading(true);
      setErrorMessageKey("");

      const { validatedAmount, discountError } = await validateDiscountCode(discountCode, basketProducts, module.id, discountInstanceId, module.posSettingInstanceId);

      if (discountError) {
        let errorMessageKey = "errors:Discount.Generic";

        if (discountError.includes("invalid")) {
          errorMessageKey = "errors:Discount.Invalid";
        }

        setErrorMessageKey(errorMessageKey);

        dispatch(checkoutActions.setCheckoutSessionDiscount(null));
      } else {
        if (validatedAmount === 0) {
          setErrorMessageKey("errors:Discount.NoValue");
        } else {
          const checkoutDiscount: ICheckoutDiscount = { discountCode: discountCode, amount: validatedAmount };
          dispatch(checkoutActions.setCheckoutSessionDiscount(checkoutDiscount));
        }
      }

      setValidationIsLoading(false);
    },
    [module, basketProducts, discountInstanceId, dispatch]
  );

  useEffect(() => {
    if (previousBasketProductTotal.current !== basketProductTotal) {
      // Ignoring if we don't have a validated discount yet
      if (discountCode) {
        handleDiscountValidation(discountCode);
      }
      previousBasketProductTotal.current = basketProductTotal;
    }
  }, [basketProductTotal, discountCode, handleDiscountValidation]);

  const resetDiscountSession = () => {
    setValidationIsLoading(false);
    setErrorMessageKey("");

    dispatch(checkoutActions.setCheckoutSessionDiscount(null));
  };

  return {
    discountCode,
    amount,
    validationIsLoading,
    errorMessageKey,
    handleDiscountValidation,
    resetDiscountSession,
  };
}
