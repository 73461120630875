import { usePayment } from "./usePayment";

import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useAppSelector } from "store/app";
import { useCustomDispatch } from "store/useStore";

import PaymentButtons from "./PaymentButtons";
import PaymentError from "./PaymentError";
import RestaurantInfo from "components/ui/RestaurantInfo";
import Terms from "./Terms";

export default function Payment() {
  const { productionUnit } = useAppSelector();
  const { isLoading: checkoutIsLoading } = useCheckoutSelector();
  const dispatch = useCustomDispatch();

  const { allowedButtons, termsAccepted, showRestaurantOfflineError, missingFields, setTermsAccepted, validateCheckout, handlePayment } = usePayment();

  async function handlePaymentButtonClick(paymentType: string, additionalPaymentParameter?: string) {
    const validated = await validateCheckout();

    if (validated) {
      await handlePayment(paymentType, additionalPaymentParameter);
    } else {
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    }
  }

  return (
    <div className="payment-section">
      <div className="finalize-buttons-body">
        <Terms checked={termsAccepted} updateChecked={(checked: boolean) => setTermsAccepted(checked)} />

        <PaymentButtons allowedButtons={allowedButtons} disabled={checkoutIsLoading || !termsAccepted} handlePaymentButtonClick={handlePaymentButtonClick} />
      </div>

      <PaymentError showRestaurantOfflineError={showRestaurantOfflineError} missingFields={missingFields} />

      <div className="paymentcards"></div>

      {productionUnit && <RestaurantInfo productionUnit={productionUnit} />}
    </div>
  );
}
