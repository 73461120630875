import { Component } from "react";

import ErrorMessage from "components/ui/ErrorMessage";
import { customInsightsEvent, logError } from "utils/helpers/errors";

import "./ErrorBoundary.scss";

export default class ErrorBoundary extends Component<{ children: any }, { hasError: boolean }> {
  // Catches app crashes, to show a proper error page
  constructor(props: any) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    logError(error);
    logError(errorInfo);

    if (error && error.message) {
      customInsightsEvent("ShopError", { Message: error.message });
    }
  }

  handleButtonClick() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="page-view error-boundary-page">
          <div className="page-view-content inner">
            <ErrorMessage isErrorBoundary handleButtonClick={this.handleButtonClick} />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
