import da from "date-fns/locale/da";
import { useMemo, useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker"; // https://reactdatepicker.com/
import { useTranslation } from "react-i18next";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import { useCheckoutSelector } from "store/checkout";

import "./Datepicker.scss";

interface IDatepickerProps {
  availableDates: Date[];
  handleDateChange: (date: string, isDelivery: boolean) => void;
}

export default function Datepicker(props: IDatepickerProps) {
  const { availableDates } = props;
  const { handleDateChange } = props; // updateAvailableDates

  const { checkoutSession, isLoading: checkoutIsLoading } = useCheckoutSelector();
  const { isDelivery, chosenDate } = checkoutSession;
  const { t } = useTranslation();

  const dateAsJSDate = useMemo(() => (chosenDate ? moment(chosenDate, "DD-MM-YYYY").toDate() : undefined), [chosenDate]);

  // Registering a locale to handle language in the datepicker
  registerLocale("da", da);

  // Hack to disable keyboard in the datepicker on mobile devices
  const onDatepickerRef = useCallback((el: any) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }, []);

  async function onDateChange(date: Date) {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    handleDateChange(formattedDate, isDelivery);
  }

  return (
    <div className="datepicker-section">
      <DatePicker id="datepicker" dateFormat="d. MMMM" locale="da" includeDates={availableDates} selected={dateAsJSDate} disabled={checkoutIsLoading} highlightDates={dateAsJSDate ? [dateAsJSDate] : []} todayButton={t("checkout:DeliveryMethod.Today")} placeholderText={chosenDate ? chosenDate : t("checkout:DeliveryMethod.Datepicker")} calendarClassName={checkoutIsLoading || availableDates.length === 0 ? "hide-calendar" : ""} ref={(el) => onDatepickerRef(el)} onChange={onDateChange}>
        <div className="dates-loading-container">{!checkoutIsLoading && availableDates.length === 0 ? <p className="dates-loading-text">{t("errors:DeliveryMethod.NoAvailableTimes")}</p> : null}</div>
      </DatePicker>

      {!chosenDate && <div className="input-error">{t("errors:DeliveryMethod.Datepicker")}</div>}
    </div>
  );
}
