import { ISessionItem } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Counter from "components/ui/Counter";

import "./OrderListItem.scss";

interface IOrderListItemProps {
  product: ISessionItem;

  hideEditOptions?: boolean; // Items cannot be edited at all, so hide the options
  disableEditOptions: boolean; // Temporarily not allowed to edit the items

  itemCountChanged: (productId: number, newCount: number) => void;
}

export default function OrderListItem(props: IOrderListItemProps) {
  const { product, hideEditOptions, disableEditOptions } = props;
  const { itemCountChanged } = props;

  const { t } = useTranslation();

  let [showMobileCounter, setShowMobileCounter] = useState(false); // Only showing +/- on mobile after click

  const productEditIsDisabled = product.editingIsDisabled; // This particular product cannot be edited

  function handleItemCountChanged(newCount: number) {
    itemCountChanged(product.productId, newCount);
  }

  // Showing the counter on click if we're on mobile
  function handleListItemClick(event: React.MouseEvent) {
    if (!productEditIsDisabled && !hideEditOptions && !disableEditOptions) {
      const eventTarget = event.target as HTMLDivElement;
      if (eventTarget && eventTarget.classList && !eventTarget.classList.contains("count-btn")) {
        setShowMobileCounter(!showMobileCounter);
      }
    }
  }

  return (
    <div className={productEditIsDisabled ? "disabled orderlist-item" : "orderlist-item"} onClick={handleListItemClick}>
      {product.imageUrl && <div className="orderlist-item-image" style={{ backgroundImage: "url(" + product.imageUrl + ")" }}></div>}

      <div className={showMobileCounter ? "counter-open orderlist-item-name-container" : "orderlist-item-name-container"} id={product.productId.toString()}>
        <p className="orderlist-item-name">
          <span className="count-text">{product.amount + " x "}</span>
          <span className="name-text"> {product.name}</span>

          {product.choices && product.choices.length > 0 && <br />}
          {product.choices &&
            product.choices.map(function (choice) {
              return (
                <span className="orderlist-item-modifier" key={choice.id ? choice.id : choice.choiceId} style={{ display: choice.amount > 0 ? "block" : "none" }}>
                  {choice.amount} x {choice.name}{" "}
                </span>
              );
            })}
        </p>

        {!hideEditOptions && <Counter count={product.amount} isDisabled={disableEditOptions} handleChange={handleItemCountChanged} />}
      </div>

      <div className="orderlist-item-price-container">
        <p className="orderlist-item-price">
          {formatPrice(product.totalPrice)} {t("common:Currency")}
        </p>
      </div>
    </div>
  );
}
