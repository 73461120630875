import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import PageTitle from "components/ui/PageTitle";

import maintenanceIcon from "assets/img/icons/maintenance.svg";
import "./Maintenance.scss";

export default function Maintenance() {
  const { t } = useTranslation();
  const redirect = useNavigate();

  return (
    <>
      <div className="maintenance-icon-container">
        <img loading="lazy" className="maintenance-icon" src={maintenanceIcon} alt="maintenance" />
      </div>

      <PageTitle title={t("pagetitles:MaintenancePage.Title")} />

      <div className="content-body">
        <p className="status-page-text">{t("errors:Maintenance")}</p>
        <button className="button" onClick={() => redirect("/")}>
          {t("errors:Actions.TryAgain")}
        </button>
      </div>
    </>
  );
}
