import { useTranslation } from "react-i18next";
import { ICheckoutSectionProps } from "@crunchit/types";

import { useCheckoutSelector } from "store/checkout";

import useDeliveryMethod from "./useDeliveryMethod";
import useAvailableTimes from "./useAvailableTimes";

import SectionWrapper from "components/checkout/SectionWrapper";

import AddressInput from "./AddressInput";
import DateTimePickers from "./DateTimePickers";
import DeliveryFee from "./DeliveryFee";
import DeliveryMethodPicker from "./DeliveryMethodPicker";

import "./DeliveryMethod.scss";

export default function DeliveryMethod(props: ICheckoutSectionProps) {
  const { index } = props;

  const { t } = useTranslation();
  const { checkout, checkoutSession } = useCheckoutSelector();

  // Keeping track of values in hooks here
  const {
    deliveryIsVisible,
    deliveryIsDisabled,
    errorInDeliveryAddress,

    handleDeliveryMethodUpdate,
    handleDeliveryAddressUpdate,
  } = useDeliveryMethod();

  const dateTimePickerState = useAvailableTimes();
  const { errorInDeliveryDate } = dateTimePickerState;

  return (
    <div className="delivery-method-section">
      <SectionWrapper index={index} title={deliveryIsVisible ? t("checkout:DeliveryMethod.PickupOrDeliveryTitle") : t("checkout:DeliveryMethod.PickupTitle")}>
        {deliveryIsVisible && <DeliveryMethodPicker deliveryIsDisabled={deliveryIsDisabled} handleDeliveryMethodUpdate={handleDeliveryMethodUpdate} />}

        {checkoutSession.isDelivery && <AddressInput errorInDeliveryAddress={errorInDeliveryAddress || errorInDeliveryDate} handleDeliveryAddressUpdate={handleDeliveryAddressUpdate} />}

        <DateTimePickers {...dateTimePickerState} />

        {checkoutSession.isDelivery && checkoutSession.chosenTimeslot && <DeliveryFee fee={checkout.delivery ? checkout.delivery.deliveryFee : 0} />}
      </SectionWrapper>
    </div>
  );
}
