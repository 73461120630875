import { IChoice, IModifierChoice, IProductModifier, ISelectedChoice } from "@crunchit/types";
import { getSingleChoicePrice } from "@crunchit/utilities";
import { useCallback } from "react";

import useChoices from "./useChoices";
import Choice from "./Choice";

import "./Choices.scss";

interface IChoiceListProps {
  choices: IChoice[];
  modifierChoices: IModifierChoice[];
  selectedChoices?: ISelectedChoice[];
  productModifier: IProductModifier;
  handleChoiceCountUpdate: (choiceId: number, newCount: number) => void;
}

export default function Choices(props: IChoiceListProps) {
  const { selectedChoices, choices, modifierChoices, productModifier, handleChoiceCountUpdate } = props;

  const {
    getChoice,
    getChoiceCount,
    showChoicePrice,

    disableChoiceIncrease,
  } = useChoices(productModifier, modifierChoices, selectedChoices);

  const setupDefaultChoiceValue = useCallback(
    (modifierChoice: IModifierChoice) => {
      if (modifierChoice.defaultChoiceAmount > 0) {
        handleChoiceCountUpdate(modifierChoice.choiceId, modifierChoice.defaultChoiceAmount);
      }
    },
    [handleChoiceCountUpdate]
  );

  return (
    <div className="choice-list collapsible-content">
      {modifierChoices.map(function (modifierChoice, index) {
        const choice = getChoice(modifierChoice.choiceId, choices);

        return !choice ? null : <Choice key={index} choice={choice} choiceCount={getChoiceCount(modifierChoice)} choicePrice={getSingleChoicePrice(modifierChoice.overwritePrice, choice.defaultPrice)} disableChoiceIncrease={disableChoiceIncrease} showChoicePrice={(choicePrice: number) => showChoicePrice(choicePrice, modifierChoice)} handleChoiceCountUpdate={(newCount: number) => handleChoiceCountUpdate(modifierChoice.choiceId, newCount)} handleChoiceMount={() => setupDefaultChoiceValue(modifierChoice)} />;
      })}
    </div>
  );
}
