import { get, post } from "@crunchit/http-client";
import { IContext, ILabel, IProduct } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_PRODUCT_URI || `product.${getEnvironment()}crunchy.ninja/api`;

const ProductService = {
  getProductById: async (productId: number, productInstanceId: string) => {
    return get<IProduct>(`${api}/product/${productId}?instanceId=${productInstanceId}`, getToken());
  },
  getLabels: async (productInstanceId: string) => {
    return get<ILabel[]>(`${api}/label?instanceId=${productInstanceId}`, getToken());
  },
  createContext: async (productIds: number[]) => {
    return post<IContext>(`${api}/context`, productIds, getToken());
  },
};

export default ProductService;
