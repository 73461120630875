import { IChoice } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import { useEffect } from "react";

import Counter from "components/ui/Counter";
import useTranslationText from "hooks/useTranslationText";

interface IChoiceProps {
  choice: IChoice;

  choiceCount: number;
  choicePrice: number;
  disableChoiceIncrease: boolean;

  showChoicePrice: (choicePrice: number) => boolean;
  handleChoiceCountUpdate: (newCount: number) => void;
  handleChoiceMount: () => void;
}

export default function Choice(props: IChoiceProps) {
  const { choice, choiceCount, choicePrice, disableChoiceIncrease } = props;
  const { showChoicePrice, handleChoiceCountUpdate, handleChoiceMount } = props;
  
  const { getTranslationText } = useTranslationText();

  useEffect(() => {
    handleChoiceMount();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="choice">
      <p className="choice-name">{choice ? getTranslationText(choice.name) : ""}</p>
      {showChoicePrice(choicePrice) && (
        <p className="choice-price">
          <span>+ {formatPrice(choicePrice)},-</span>
        </p>
      )}

      <Counter count={choiceCount} disableIncrease={disableChoiceIncrease} handleChange={handleChoiceCountUpdate} />
    </div>
  );
}
