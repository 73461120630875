import { useMemo } from "react";

import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { useCheckoutSelector } from "store/checkout";
import { useMenuSelector } from "store/menu";
import { browserIsOutdated } from "utils/helpers/errors";

export default function useAppState() {
  const app = useAppSelector();
  const basket = useBasketSelector();
  const menu = useMenuSelector();
  const checkout = useCheckoutSelector();

  const error = app.error || basket.error || menu.error;
  const isError = !!error || browserIsOutdated();

  const isLoading = app.isLoading || basket.isLoading || menu.isLoading || checkout.isLoading;

  const checkoutLoadingMessageKey = checkout.checkoutLoadingMessageKey;

  const loadingMessageKey = useMemo(() => {
    let key = "";

    if (checkoutLoadingMessageKey) {
      key = checkoutLoadingMessageKey;
    }

    return key;
  }, [checkoutLoadingMessageKey]);

  return { isError, error, isLoading, loadingMessageKey };
}
