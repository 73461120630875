import { IBlockedChoice, IBlockedProduct, ICategoryProduct, IContext, ILabel } from "@crunchit/types";
import { isBlockedProduct } from "@crunchit/utilities";
import { useCallback, useState } from "react";
import ProductService from "services/ProductService";
import WarehouseService from "services/WarehouseService";

import { useAppSelector } from "store/app";
import { menuActions } from "store/menu";
import { useCustomDispatch } from "store/useStore";

const initialContext = { products: [], modifiers: [], choices: [] };

export default function useProducts() {
  const { module } = useAppSelector();

  const dispatch = useCustomDispatch();

  let [context, setContext] = useState<IContext>(initialContext);

  let [blockedProducts, setBlockedProducts] = useState<IBlockedProduct[]>([]);
  let [blockedChoices, setBlockedChoices] = useState<IBlockedChoice[]>([]);

  let [labels, setLabels] = useState<ILabel[]>([]);

  const getAvailableProductIds = useCallback(
    (productIds: number[]) => {
      return productIds.filter((id) => !isBlockedProduct(id, blockedProducts));
    },
    [blockedProducts]
  );

  const loadWarehouse = useCallback(async () => {
    const [blockedProductsResponse, blockedChoicesResponse] = await Promise.all([WarehouseService.getBlockedProducts(module.productionUnitId), WarehouseService.getBlockedChoices(module.productionUnitId)]);

    if (blockedProductsResponse.isSuccess()) {
      setBlockedProducts(blockedProductsResponse.data);
    }

    if (blockedChoicesResponse.isSuccess()) {
      setBlockedChoices(blockedChoicesResponse.data);
    }
  }, [module.productionUnitId]);

  const loadProducts = useCallback(
    async (categoryProducts: ICategoryProduct[]) => {
      let productIds = categoryProducts.map((obj) => obj.productId);

      // Getting products and modifiers
      if (productIds && productIds.length) {
        dispatch(menuActions.setIsLoading(true));

        try {
          const contextResponse = await ProductService.createContext(productIds);

          if (contextResponse.isSuccess()) {
            setContext(contextResponse.data);
          }
        } catch (error) {
          console.error(error);
        }

        dispatch(menuActions.setIsLoading(false));
      } else {
        setContext(initialContext);
      }
    },
    [dispatch]
  );

  const loadLabels = useCallback(async () => {
    if (module.productInstanceId) {
      try {
        const labelsResponse = await ProductService.getLabels(module.productInstanceId);

        if (labelsResponse.isSuccess()) {
          setLabels(labelsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [module.productInstanceId]);

  return {
    context,

    blockedProducts,
    blockedChoices,

    labels,

    loadProducts,
    loadWarehouse,
    loadLabels,

    getAvailableProductIds,
  };
}
