import { ILanguageKey, ITranslation } from "@crunchit/types";
import { useTranslation } from "react-i18next";

export default function useTranslationText() {
  const { i18n } = useTranslation();

  function getTranslationText(translations: ITranslation) {
    return translations[i18n.language as ILanguageKey] || translations["da-DK"] || "";
  }

  return { getTranslationText };
}
