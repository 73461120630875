import i18n from "i18n";

import ModuleService from "services/ModuleService";
import CustomerSettingService from "services/CustomerSettingService";
import StorageService from "services/StorageService";
import { IFrontendSettings } from "models/app";

export async function getModuleFromDomain() {
  let productionUnitId;
  let moduleId;

  // OBS: We only use the productionUnitId and moduleId since we cannot trust the full module response in this request
  const moduleFromUrlResponse = await ModuleService.getModuleByDomain(window.location.hostname);

  if (moduleFromUrlResponse.isSuccess()) {
    productionUnitId = moduleFromUrlResponse.data.productionUnitId;
    moduleId = moduleFromUrlResponse.data.id;
  }

  return { productionUnitId, moduleId };
}

export async function setupLanguage() {
  let languages: string[] = [];
  let languageToUse = "da-DK";

  const languageSettings = await CustomerSettingService.getLanguage();

  if (languageSettings.isSuccess() && languageSettings.data.defaultLanguage) {
    // Only handling one language per account for now
    languages = languageSettings.data.languages;
    languageToUse = languageSettings.data.defaultLanguage;

    if (languageToUse && languageToUse !== "da-DK" && i18n.language !== languageToUse) {
      i18n.changeLanguage(languageToUse);
    }
  }

  return languages;
}

export async function getMaintenanceMode() {
  let isDownForMaintenance = false;

  if (process.env.NODE_ENV === "production") {
    const maintenanceModeResponse = await StorageService.getMaintenanceMode();

    if (maintenanceModeResponse.isSuccess()) {
      isDownForMaintenance = maintenanceModeResponse.data.isDownForMaintenance;
    }
  }

  return isDownForMaintenance;
}

export async function getFrontendSettings(moduleId: string) {
  const frontendSettingsResponse = await CustomerSettingService.getFrontendSettingsByModule(moduleId);

  if (!frontendSettingsResponse.isSuccess()) {
    throw new Error("Unable to get frontend settings");
  }

  const features = frontendSettingsResponse.data.features || [];

  const mappedSettings = features.reduce((acc, { name, value }) => {
    try {
      const parsedValue = JSON.parse(value);
      return acc.set(name, parsedValue);
    } catch (error) {
      return acc.set(name, value);
    }
  }, new Map<string, string | number | boolean>());

  const showClosedFrontpageMessage = (mappedSettings.get("showClosedFrontpageMessage") as boolean) || false;
  const onlinePayment = (mappedSettings.get("onlinePayment") as boolean) || false;
  const mobilepay = (mappedSettings.get("mobilepay") as boolean) || false;
  const payAtPickup = (mappedSettings.get("payAtPickup") as boolean) || false;

  const precheckoutCategoryValue = mappedSettings.get("precheckoutCategory");
  const startCategoryValue = mappedSettings.get("startCategory");
  const bagFeeItemValue = mappedSettings.get("bagFeeItem");

  let precheckoutCategory, startCategory, bagFeeItem;

  if (precheckoutCategoryValue) {
    precheckoutCategory = precheckoutCategoryValue as number;
  }

  if (startCategoryValue) {
    startCategory = startCategoryValue as number;
  }

  if (bagFeeItemValue) {
    bagFeeItem = bagFeeItemValue as number;
  }

  const logo = mappedSettings.get("logo") as string;
  const smileyUrl = mappedSettings.get("smileyUrl") as string;
  const stylingClass = mappedSettings.get("stylingClass") as string;
  const backgroundImage = mappedSettings.get("backgroundImage") as string;
  const roomServiceFeeProductId = mappedSettings.get("roomserviceFeeItem") as number;
  const autoShowModifiers = (mappedSettings.get("autoShowModifiers") as boolean) || false;
  const checkoutGuests = (mappedSettings.get("checkoutGuests") as boolean) || false;

  const frontendSettings: IFrontendSettings = {
    showClosedFrontpageMessage,
    onlinePayment,
    mobilepay,
    payAtPickup,
    precheckoutCategory,
    startCategory,
    bagFeeItem,
    logo,
    smileyUrl,
    stylingClass,
    backgroundImage,
    roomServiceFeeProductId,
    autoShowModifiers,
    checkoutGuests,
  };

  return frontendSettings;
}
