import { useAppSelector } from "store/app";
import "./Logo.scss";

interface ILogoProps {
  handleClick: () => void;
}

export default function Logo(props: ILogoProps) {
  const { handleClick } = props;
  const { appSettings } = useAppSelector();

  return <div className="logo-container">{appSettings.logo && <img loading="lazy" className="logo" onClick={handleClick} src={appSettings.logo} alt="logo" />}</div>;
}
