import { IChoice, IModifier, IProductModifier, ISelectedChoice } from "@crunchit/types";
import { formatPrice, getTotalChoiceCount } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Choices from "components/menu/Choices";
import useTranslationText from "hooks/useTranslationText";
import { getTotalChoicePriceForModifier } from "utils/helpers/menu";

interface IModifierViewProps {
  isExpanded: boolean;
  isCollapsible: boolean;
  modifierIndex: number;

  modifier: IModifier;
  productModifier: IProductModifier;

  choices: IChoice[];
  selectedChoices?: ISelectedChoice[];

  modifierHasWarning: (modifier: IModifier) => boolean;
  handleChoiceCountUpdate: (choiceId: number, modifier: IModifier, newCount: number) => void;

  dismiss: () => void;
  expand: () => void;
}

export default function Modifier(props: IModifierViewProps) {
  const { isExpanded, isCollapsible, modifier, modifierIndex, productModifier, choices, selectedChoices } = props;
  const { handleChoiceCountUpdate, modifierHasWarning, dismiss, expand } = props;

  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();

  const totalChoiceCount = selectedChoices ? getTotalChoiceCount(selectedChoices) : 0;

  const showWarning = useMemo(() => (!isCollapsible || !isExpanded) && modifierHasWarning(modifier), [isCollapsible, isExpanded, modifier, modifierHasWarning]);

  const totalChoicePrice = useMemo(() => {
    return selectedChoices && totalChoiceCount > 0 ? getTotalChoicePriceForModifier(modifier, productModifier.choicesIncluded, choices, selectedChoices) : 0;
  }, [modifier, productModifier, choices, selectedChoices, totalChoiceCount]); // including totalChoiceCount to force update of price

  const selectedChoicesText = useMemo(() => {
    // The text to display on a collapsed modifier
    let text = "";

    if (selectedChoices && totalChoiceCount > 0) {
      const chosenTextsList = selectedChoices.map((selectedChoice: ISelectedChoice) => {
        let choiceText = "";

        const foundChoice = choices.find((c: IChoice) => c.id === selectedChoice.choiceId);

        if (selectedChoice.amount > 0 && foundChoice) {
          choiceText = `${selectedChoice.amount} x ${getTranslationText(foundChoice.name)}`;
        }

        return choiceText;
      });

      if (chosenTextsList.length > 0) {
        text = chosenTextsList.reduce((final: string, current: string) => {
          return final !== "" ? `${final}, ${current}` : current;
        }, text);
      }
    }
    return text;
  }, [totalChoiceCount, selectedChoices, choices, getTranslationText]);

  function toggleExpandedModifier() {
    isExpanded ? dismiss() : expand();
  }

  return (
    <div className={`modifier ${isCollapsible ? "collapsible-section" : "non-collapsible-section"} ${isExpanded ? "expanded" : "collapsed"}`}>
      {isCollapsible && (
        <h3 className="modifier-title" onClick={toggleExpandedModifier} id={modifier.id.toString()}>
          <span className="modifier-counter">0{modifierIndex}</span> {getTranslationText(modifier.name)} <span className="collapsible-icon"></span>
        </h3>
      )}

      {showWarning && (
        <p className="single-missing-choices-warning">
          {t("errors:Products.IndividualModifierChoicesMissing")} {productModifier.minChoices} {getTranslationText(modifier.name)}
        </p>
      )}

      <div className="collapsed-text">
        {selectedChoicesText !== "" && (
          <p className="collapsed-chosen-options">
            <span>
              {t("products:Chosen")}: {selectedChoicesText}
            </span>
          </p>
        )}
        {totalChoicePrice > 0 && (
          <p className="collapsed-price">
            + {formatPrice(totalChoicePrice)} {t("common:Currency")}
          </p>
        )}
      </div>

      <Choices choices={choices} modifierChoices={modifier.choices} selectedChoices={selectedChoices} productModifier={productModifier} handleChoiceCountUpdate={(choiceId: number, newCount: number) => handleChoiceCountUpdate(choiceId, modifier, newCount)} />

      {isCollapsible && (
        <div className="choice-list-collapse collapsible-content">
          <div onClick={toggleExpandedModifier} className="collapsible-icon"></div>
        </div>
      )}
    </div>
  );
}
