import { useState } from "react";
import { IBlockedChoice, IContext, ILabel, IProduct } from "@crunchit/types";

import useBasketUpdates from "hooks/useBasketUpdates";
import Modifiers from "components/menu/Modifiers";
import { useAppSelector } from "store/app";

import ProductDetails from "./ProductDetails";
import "./Product.scss";

interface IProductViewProps {
  product: IProduct;
  context: IContext;
  isBlockedProduct: boolean;
  blockedChoices: IBlockedChoice[];
  labels?: ILabel[];
}

export default function Product(props: IProductViewProps) {
  const { product, context, isBlockedProduct, blockedChoices, labels } = props;

  const { handleBasketChange } = useBasketUpdates();
  const { appSettings } = useAppSelector();

  const expandAllModifiers = appSettings.autoShowModifiers || false;

  const hasModifiers = product.modifiers && product.modifiers.length > 0;
  const startAsExpanded = hasModifiers && expandAllModifiers;

  let [isExpanded, setIsExpanded] = useState(startAsExpanded);

  function countChanged(newCount: number) {
    handleBasketChange({ newCount, productId: product.id, product });
  }

  function toggleModifiers() {
    if (expandAllModifiers) {
      return;
    }

    if (hasModifiers) {
      // Scrolling down when modifier dropdown is opened
      if (!isExpanded) {
        let elem = document.getElementById(product.id.toString());
        window.setTimeout(function () {
          if (elem) {
            let elementTop = elem.offsetTop;
            window.scrollTo({ top: elementTop - 80, behavior: "smooth" }); // Header on mobile is 80px
          }
        }, 100);
      }

      setIsExpanded(!isExpanded);
    }
  }

  return (
    <div className={`product ${isBlockedProduct ? "blocked" : ""} ${isExpanded ? "modifiers-expanded" : ""}`}>
      <div className="product-content">
        {product.imageUrl && <div className="product-image" onClick={toggleModifiers} style={{ backgroundImage: "url(" + product.imageUrl + ")" }}></div>}

        <ProductDetails product={product} isExpanded={isExpanded} labels={labels} countChanged={countChanged} toggleModifiers={toggleModifiers} />
      </div>

      {isExpanded && <Modifiers product={product} context={context} blockedChoices={blockedChoices} isCollapsible={!expandAllModifiers} dismiss={toggleModifiers} />}
    </div>
  );
}
