import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./More.scss";

interface IMoreProps {
  isOpen: boolean;
  handleClick: () => void;
}

export default function More(props: IMoreProps) {
  const { isOpen, handleClick } = props;

  const { t } = useTranslation();

  const textTranslationKey = useMemo(() => (isOpen ? "common:Less" : "common:More"), [isOpen]);

  return (
    <button className="more-button" onClick={handleClick}>
      <span className="icon">...</span>
      <span className="text">{t(textTranslationKey)}</span>
    </button>
  );
}
