import { ICategory, ICriticalError, IMenu } from "@crunchit/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import MenuService from "services/MenuService";

type Rejection = { rejectValue: ICriticalError };
type LoadCategoriesParams = { menuInstanceId: string; existingMenuId: string };
type LoadCategoriesSuccess = { menuId: string; categories: ICategory[] };

export const loadCategories = createAsyncThunk<LoadCategoriesSuccess, LoadCategoriesParams, Rejection>("menu/loadCategories", async (data, { rejectWithValue }) => {
  const { menuInstanceId, existingMenuId } = data;
  let menuId;

  try {
    if (existingMenuId) {
      menuId = existingMenuId;
    } else {
      const menusResponse = await MenuService.getMenus(menuInstanceId);

      if (!menusResponse.isSuccess()) {
        return rejectWithValue({ message: `Unable to fetch categories: no menu with menu instance id '${menuInstanceId}'`, error: menusResponse.errors });
      }

      const activeMenu = menusResponse.data.find((m: IMenu) => m.active);

      if (!activeMenu) {
        return rejectWithValue({ message: `No active menus within menu instance '${menuInstanceId}'` });
      }

      menuId = activeMenu.id.toString();
    }

    const categoriesResponse = await MenuService.getCategories(menuId, menuInstanceId);
    let categories: ICategory[] = [];

    if (categoriesResponse.isSuccess()) {
      categories = categoriesResponse.data;
    }

    return { menuId, categories };
  } catch (error) {
    return rejectWithValue({ message: "Unable to load categories", error: error instanceof Error ? error.message : error });
  }
});

const thunks = {
  loadCategories,
};

export default thunks;
