import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";
import "./DeliveryFee.scss";

export default function DeliveryFee(props: { fee: number }) {
  const { fee } = props;
  const { t } = useTranslation();

  return (
    <>
      {fee > 0 && (
        <div className="delivery-fee">
          <div className="delivery-fee-background"></div>
          <p className="delivery-fee-text">{t("checkout:DeliveryMethod.DeliveryFee")}</p>
          <p className="delivery-fee-price">
            {formatPrice(fee)} {t("common:Currency")}
          </p>
        </div>
      )}
    </>
  );
}
