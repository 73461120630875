import ProductionUnitService from "services/ProductionUnitService";

export default function useOpeningHours() {
  function stringTimeToDate(time: string) {
    const [hour, minutes] = time.split(":");
    const date = new Date();

    date.setHours(parseInt(hour));
    date.setMinutes(parseInt(minutes));
    date.setSeconds(0, 0);

    return date;
  }

  async function getRestaurantIsOpen(productionUnitId: number) {
    const response = await ProductionUnitService.getOpeningHoursToday(productionUnitId);

    if (!response.isSuccess() || !response.data) {
      return false;
    }

    const nowTime = new Date().getTime();
    const openingTime = stringTimeToDate(response.data.open).getTime();
    const closingTime = stringTimeToDate(response.data.close).getTime();

    return nowTime >= openingTime && nowTime <= closingTime;
  }

  return {
    getRestaurantIsOpen,
  };
}
