import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";

import { useBasketSelector } from "store/basket";

import "./MiniBasket.scss";

interface IMiniBasketProps {
  handleClick: () => void;
}

export default function MiniBasket(props: IMiniBasketProps) {
  const { handleClick } = props;

  const { t } = useTranslation();
  const { basketProductTotal, basketProductCount } = useBasketSelector();

  return (
    <button className="click-allowed minicart" onClick={handleClick}>
      <div className="minicart-image">{basketProductCount > 0 && <span className="ordernumber">{basketProductCount}</span>}</div>

      <h3 className="click-allowed ordertotal">
        {t("cart:MinicartTotal")}{" "}
        <span className="basket-total-highlight">
          {formatPrice(basketProductTotal)} {t("common:Currency")}
        </span>
      </h3>
    </button>
  );
}
