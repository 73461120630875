import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";
import { toggleNoScroll } from "utils/helpers/view";

export default function useHeaderNavigation() {
  let [basketIsOpen, setBasketIsOpen] = useState(false);
  let [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const { appSettings } = useAppSelector();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hideNavigation = appSettings.showClosedFrontpageMessage;

  const collapseAllNavigation = useCallback(() => {
    setBasketIsOpen(false);
    setSidebarIsOpen(false);
  }, []);

  // Custom redirect for header, with optional checks
  const handleRedirect = useCallback(
    (path: string) => {
      let continueRedirect = false;

      // Order redirect check
      if (window.location.href.indexOf("/order/") > -1 && path === "/") {
        let c = window.confirm(t("errors:Order.ConfirmRedirectMessage"));
        if (c) {
          continueRedirect = true;
        }
      } else {
        continueRedirect = true;
      }

      if (continueRedirect) {
        collapseAllNavigation();
        navigate(path);
      }
    },
    [t, collapseAllNavigation, navigate]
  );

  function toggleSidebar() {
    toggleNoScroll(!sidebarIsOpen); // When the sidebar is open, disable scroll on the main content

    setBasketIsOpen(false);
    setSidebarIsOpen(!sidebarIsOpen);
  }

  function toggleBasket() {
    toggleNoScroll(!basketIsOpen); // When the cart is open, disable scroll on the main content

    setBasketIsOpen(!basketIsOpen);
    setSidebarIsOpen(false);
  }

  // Checking if our settings dictate the minicart always goes straight to checkout
  const handleMiniBasketClick = () => {
    if (appSettings.showExpandedCart) {
      toggleBasket();
    } else {
      // Fasttrack - go directly to checkout
      handleRedirect("/checkout");
    }
  };

  // Continue from cart to precheckout or checkout
  const handleContinueClick = () => {
    let path = appSettings.precheckoutCategory ? "/precheckout" : "/checkout";
    if (window.location.href.indexOf("precheckout") > -1) {
      path = "/checkout";
    }
    handleRedirect(path);
  };

  return {
    hideNavigation,
    basketIsOpen,
    sidebarIsOpen,
    handleRedirect,
    collapseAllNavigation,
    clickHandlers: {
      sidebar: toggleSidebar,
      sidebarCategory: (categoryId: number) => handleRedirect("/menu/" + categoryId),
      logo: () => handleRedirect("/"),
      basket: handleMiniBasketClick,
      continue: handleContinueClick,
    },
  };
}
