import { IBasketItem, ICheckoutInstanceSettings, IModule, IProductionUnit, siteStorageName } from "@crunchit/types";
import { removeFromLocalStorage } from "@crunchit/utilities";
import moment from "moment";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CheckoutBasket from "components/checkout/CheckoutBasket";
import DeliveryMethod from "components/checkout/DeliveryMethod";
import GuestInfo from "components/checkout/GuestInfo";
import RoomServiceMessage from "components/checkout/RoomServiceMessage";
import OrderComment from "components/checkout/OrderComment";
import Payment from "components/checkout/Payment";
import useCheckoutFees from "hooks/useCheckoutFees";
import useOpeningHours from "hooks/useOpeningHours";
import CheckoutService from "services/CheckoutService";
import { useAppSelector, appActions } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import { getCurrentCheckout, getPreviousCheckout } from "utils/helpers/checkout/session";

import "./Checkout.scss";

export default function Checkout() {
  const { appSettings, appIsInitialized, module, isRoomService, isOrderAtTAble, productionUnit } = useAppSelector();
  const { bagFeeProductId, basket, basketProducts } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutInstanceSettings, checkoutSession } = useCheckoutSelector();
  const { getRestaurantIsOpen } = useOpeningHours();
  const { initializeFee } = useCheckoutFees();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  const [checkoutIsInitializing, setCheckoutIsInitializing] = useState(false);
  const [checkoutIsInitialized, setCheckoutIsInitialized] = useState(false);
  const [availableTime, setAvailableTime] = useState<string>();

  const readyToAddFees = useMemo(() => checkout.id && basket.id && basketProducts.length > 0 && module.productInstanceId, [checkout.id, basket.id, basketProducts, module.productInstanceId]);

  const handleError = useCallback(
    (error: unknown) => {
      dispatch(appActions.setError({ message: "Checkout error", error }));
    },
    [dispatch]
  );

  const handleCheckoutInitializationError = useCallback(() => {
    removeFromLocalStorage(siteStorageName, "checkoutId");
    dispatch(checkoutActions.setCheckout(null));
    dispatch(checkoutActions.setIsLoading({ isLoading: false }));
  }, [dispatch]);

  const getCheckout = useCallback(async (moduleId: string, checkoutInstanceId: string) => {
    const previousCheckout = await getPreviousCheckout();
    const checkout = await getCurrentCheckout({ moduleId, checkoutInstanceId, previousCheckout });

    if (!checkout) {
      throw new Error("Unable to get initial checkout");
    }

    return { previousCheckout, checkout };
  }, []);

  const getCheckoutInstanceSettings = useCallback(async (checkoutInstanceId: string) => {
    const checkoutInstanceSettingsResponse = await CheckoutService.getCheckoutInstanceSettings(checkoutInstanceId);

    if (!checkoutInstanceSettingsResponse.isSuccess()) {
      throw new Error("Unable to get checkout instance settings");
    }

    return checkoutInstanceSettingsResponse.data;
  }, []);

  const getAvailableTime = useCallback(async (date: string, module: IModule, items: IBasketItem[]) => {
    const { productionUnitId, capacityInstanceId, checkoutInstanceId } = module;

    const response = await CheckoutService.updatePickupTimes(checkoutInstanceId, { productionUnitId, capacityInstanceId, date, items });

    if (response.isSuccess() && response.data.length > 0) {
      return response.data[0].time;
    }
  }, []);

  const initializeCheckout = useCallback(
    async (module: IModule, productionUnit: IProductionUnit, isRoomService: boolean, checkoutInstanceSettings: ICheckoutInstanceSettings, basketItems: IBasketItem[]) => {
      dispatch(checkoutActions.setIsLoading({ isLoading: true }));
      dispatch(checkoutActions.resetCheckoutSession());

      if (isRoomService) {
        const restaurantIsOpen = await getRestaurantIsOpen(productionUnit.id);

        if (!restaurantIsOpen) {
          dispatch(checkoutActions.setIsLoading({ isLoading: false }));
          alert(t("errors:Checkout.RestaurantOffline"));
          navigate("/");
          return;
        }
      }

      try {
        // Checkout
        const { previousCheckout, checkout } = await getCheckout(module.id, module.checkoutInstanceId);
        dispatch(checkoutActions.setCheckout(checkout));

        // Checkout session data
        if (previousCheckout && previousCheckout.guestInformation) {
          dispatch(checkoutActions.setCheckoutSessionGuestInfo(previousCheckout.guestInformation));
        }

        // Checkout instance settings
        let checkoutInstanceSettingsInstanceId = checkoutInstanceSettings.instanceId;

        if (!checkoutInstanceSettingsInstanceId) {
          const checkoutInstanceSettings = await getCheckoutInstanceSettings(module.checkoutInstanceId);
          checkoutInstanceSettingsInstanceId = checkoutInstanceSettings.instanceId;
          dispatch(checkoutActions.setCheckoutInstanceSettings(checkoutInstanceSettings));
        }

        // Setting today's date if future ordering not allowed
        if (checkoutInstanceSettings.guestFutureOrderDates === 0) {
          dispatch(checkoutActions.setChosenDate(moment().format("DD-MM-YYYY")));
        }

        // Getting available time for room service
        if (isRoomService) {
          const availableTime = await getAvailableTime(moment().format("DD-MM-YYYY"), module, basketItems);
          setAvailableTime(availableTime);
        }
      } catch (error) {
        handleCheckoutInitializationError();
        handleError(error instanceof Error ? error.message : error);
      } finally {
        dispatch(checkoutActions.setIsLoading({ isLoading: false }));
      }
    },
    [dispatch, getRestaurantIsOpen, getCheckout, getCheckoutInstanceSettings, getAvailableTime, handleCheckoutInitializationError, handleError, t, navigate]
  );

  useEffect(() => {
    if (appIsInitialized && productionUnit && !checkoutIsInitialized && !checkoutIsInitializing) {
      setCheckoutIsInitializing(true);

      try {
        if (!module.id || !module.checkoutInstanceId || !module.capacityInstanceId) {
          throw Error(`No checkout instance id on module '${module.id}'`);
        }

        initializeCheckout(module, productionUnit, isRoomService, checkoutInstanceSettings, basketProducts).then(() => {
          setCheckoutIsInitialized(true);
          setCheckoutIsInitializing(false);
        });
      } catch (error) {
        handleError(error);
      }
    }
  }, [appIsInitialized, productionUnit, checkoutIsInitialized, checkoutIsInitializing, module, isRoomService]); // eslint-disable-line

  // Set order ready time for room service
  useEffect(() => {
    if (isRoomService && checkout.id && checkoutSession.guestInformation && !checkoutSession.guestInformation.orderReadyTime && availableTime) {
      dispatch(checkoutActions.setCheckoutSessionGuestInfo({ ...checkoutSession.guestInformation, orderReadyTime: availableTime }));
    }
  }, [isRoomService, checkout.id, checkoutSession.guestInformation, availableTime, dispatch]);

  // Bag fee
  useEffect(() => {
    if (readyToAddFees && bagFeeProductId) {
      initializeFee(bagFeeProductId, !checkoutSession.bringOwnBagIsChecked, module.productInstanceId);
    }
  }, [readyToAddFees, bagFeeProductId, checkoutSession.bringOwnBagIsChecked, module.productInstanceId, initializeFee]);

  // Room service fee
  useEffect(() => {
    if (readyToAddFees && appSettings.roomServiceFeeProductId) {
      initializeFee(appSettings.roomServiceFeeProductId, isRoomService, module.productInstanceId);
    }
  }, [readyToAddFees, appSettings.roomServiceFeeProductId, isRoomService, module.productInstanceId, initializeFee]);

  const checkoutSections = useMemo(() => {
    if (basketProducts.length === 0) {
      return [CheckoutBasket];
    }

    const sections = [RoomServiceMessage, CheckoutBasket, GuestInfo, OrderComment];

    if (!isRoomService && !isOrderAtTAble) {
      sections.push(DeliveryMethod);
    }

    sections.push(Payment);
    return sections;
  }, [basketProducts, isRoomService, isOrderAtTAble]);

  return (
    <div className={`content-body checkout-content ${checkoutSession.showValidationErrors ? "show-validation-errors" : ""}`}>
      {checkout && (
        <div className="content-sections">
          {checkoutSections.map((CheckoutSection, index) => (
            <CheckoutSection key={index} index={index} />
          ))}
        </div>
      )}
    </div>
  );
}
