import { IChoice, IModifierChoice, IProductModifier, ISelectedChoice } from "@crunchit/types";
import { getTotalChoiceCount, hasReachedIncludedChoices, hasReachedMaxChoices } from "@crunchit/utilities";
import { useMemo, useCallback } from "react";

export default function useChoices(productModifier: IProductModifier, modifierChoices: IModifierChoice[], selectedChoices?: ISelectedChoice[]) {
  // Logic across choices in a modifier
  const totalChoiceCount = selectedChoices ? getTotalChoiceCount(selectedChoices) : 0;

  const maxReached = hasReachedMaxChoices(totalChoiceCount, productModifier.maxChoices);
  const includedReached = hasReachedIncludedChoices(totalChoiceCount, productModifier.choicesIncluded, modifierChoices);

  // Single choice logic
  const getChoice = useCallback((choiceId: number, choices: IChoice[]) => choices.find((c: IChoice) => c.id === choiceId), []);
  const getSelectedChoice = useCallback((choiceId: number) => (selectedChoices ? selectedChoices.find((c: ISelectedChoice) => c.choiceId === choiceId) : undefined), [selectedChoices]);

  const getChoiceCount = useCallback(
    (modifierChoice: IModifierChoice) => {
      const selectedChoice = getSelectedChoice(modifierChoice.choiceId);
      return selectedChoice ? selectedChoice.amount : modifierChoice.defaultChoiceAmount;
    },
    [getSelectedChoice]
  );

  const freeAmountReached = useCallback((freeAmount: number, selectedChoiceAmount?: number) => freeAmount === 0 || (selectedChoiceAmount !== undefined && freeAmount >= selectedChoiceAmount), []);
  const showChoicePrice = useCallback(
    (choicePrice: number, modifierChoice: IModifierChoice) => {
      const selectedChoice = getSelectedChoice(modifierChoice.choiceId);
      return choicePrice > 0 && includedReached && freeAmountReached(modifierChoice.freeAmount, selectedChoice?.amount);
    },
    [includedReached, freeAmountReached, getSelectedChoice]
  );

  const disableChoiceIncrease = useMemo(() => maxReached, [maxReached]);

  return {
    getChoice,
    getChoiceCount,
    showChoicePrice,
    disableChoiceIncrease,
  };
}
