import { IBasketItem, IProduct } from "@crunchit/types";
import { useBasketSelector, basketThunks } from "store/basket";
import { useCustomDispatch } from "store/useStore";

export default function useBasketUpdates() {
  const { basket, basketProducts } = useBasketSelector();

  const dispatch = useCustomDispatch();

  // An orderlist product is not directly comparable with the basket product
  const getBasketProduct = (productId: number) => basketProducts.find((p: IBasketItem) => p.productId === productId);

  const handleBasketChange = (values: { newCount: number; productId: number; product?: IProduct }) => {
    const { productId, product, newCount } = values;

    const basketProduct = getBasketProduct(productId);

    if (newCount < 1) {
      if (basketProduct) {
        dispatch(basketThunks.removeBasketItem({ basketId: basket.id, basketProductId: basketProduct.id }));
      }
    } else {
      if (basketProduct) {
        dispatch(basketThunks.updateBasketItem({ basketId: basket.id, basketProduct, updatedCount: newCount }));
      } else if (product) {
        dispatch(basketThunks.addBasketItem({ basketId: basket.id, product, count: newCount }));
      }
    }
  };

  return { handleBasketChange };
}
