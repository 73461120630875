import { formatPrice } from "@crunchit/utilities";
import { useTranslation } from "react-i18next";

import "./SimpleTotal.scss";

interface ISimpleTotalProps {
  total: number;
  text?: string;
}

export default function SimpleTotal(props: ISimpleTotalProps) {
  const { total, text } = props;
  const { t } = useTranslation();

  return (
    <div className="total simple-total">
      <h2 className="total-title">{text ? text : t("cart:TotalPrice")}</h2>
      <h2 className="total-amount">
        {formatPrice(total ? total : 0)} <span className="currency">{t("common:Currency")}</span>
      </h2>
    </div>
  );
}
