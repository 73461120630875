import i18n from "i18n";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "store/app";

import "./LanguagePicker.scss";

function getImageSrc(language: string) {
  switch (language) {
    case "en-US":
      return "https://billedarkiv.blob.core.windows.net/images/96e3f70f-dd16-4255-8ddf-358fb6f7f600";

    case "de-DE":
      return "https://crunchorderfrontend.blob.core.windows.net/images/de-DE.jpg";

    case "da-DK":
      return "https://billedarkiv.blob.core.windows.net/images/f46b958a-d2db-43fa-8b32-fd1f20a5e806";

    default:
      return "";
  }
}

export default function LanguagePicker() {
  const { languages } = useAppSelector();
  const { t } = useTranslation();

  const currentLanguage = i18n.language;
  const languagePickerClassName = "language-picker";

  const [isOpen, setIsOpen] = useState(false);

  const languageList = useMemo(() => languages.filter((l) => l !== currentLanguage), [languages, currentLanguage]);

  const switchLanguage = useCallback(
    (language: string) => {
      const languageToUse = languages.find((l) => l === language);

      if (languageToUse) {
        i18n.changeLanguage(languageToUse);
      }
    },
    [languages]
  );

  function handleClick(event: Event) {
    const target = event.target as HTMLElement;

    if (target && !target.closest(`.${languagePickerClassName}`)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClick);
    } else {
      document.removeEventListener("click", handleClick);
    }
  }, [isOpen]);

  return (
    <div className={`${languagePickerClassName} ${isOpen ? "open" : ""}`}>
      <button className="current-language" onClick={() => setIsOpen(!isOpen)}>
        <img src={getImageSrc(currentLanguage)} alt="" />
        <span>{t("common:Language")}</span>
      </button>

      {isOpen && (
        <ul className="language-picker-list">
          {languageList.map((language) => (
            <li key={language}>
              <button style={{ backgroundImage: `url(${getImageSrc(language)})` }} onClick={() => switchLanguage(language)}></button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
