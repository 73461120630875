import { get, post, put, del } from "@crunchit/http-client";
import { IBasket, IBasketItem, IBasketProductRequestBody } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_BASKET_URI || `basket.${getEnvironment()}crunchy.ninja/api`;

const BasketService = {
  getNewBasket: async () => {
    return get<IBasket>(`${api}/basket`, getToken());
  },
  getBasketById: async (id: string) => {
    return get<IBasket>(`${api}/basket/${id}`, getToken());
  },
  addProductToBasket: async (basketId: string, product: IBasketProductRequestBody) => {
    return post<IBasket>(`${api}/basket/${basketId}/products`, product, getToken());
  },
  updateBasketItem: async (basketId: string, basketProductId: string, basketItem: IBasketItem) => {
    return put<IBasket>(`${api}/basket/${basketId}/products/${basketProductId}`, basketItem, getToken());
  },
  removeBasketItem: async (basketId: string, basketProductId: string) => {
    return del<IBasket>(`${api}/basket/${basketId}/products/${basketProductId}`, getToken());
  },
};

export default BasketService;
