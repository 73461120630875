import { useTranslation } from "react-i18next";

import { useCustomDispatch } from "store/useStore";
import { useCheckoutSelector, checkoutActions } from "store/checkout";

import Checkbox from "components/ui/forms/Checkbox";

import "./BagFeeCheckbox.scss";

export default function BagFeeCheckbox() {
  const { t } = useTranslation();
  const { checkoutSession } = useCheckoutSelector();
  const dispatch = useCustomDispatch();

  const { bringOwnBagIsChecked } = checkoutSession;

  return (
    <div className="bagfee-checkbox">
      <Checkbox checked={bringOwnBagIsChecked} label={t("checkout:Cart.BringOwnBagCheckbox")} handleInputChange={(updatedChecked) => dispatch(checkoutActions.setBringOwnBagIsChecked(updatedChecked))} />
    </div>
  );
}
