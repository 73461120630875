import moment from "moment";

export const defaultCheckout = {
  id: "",
  instanceId: "",
  moduleId: "",
  createdUtc: moment().format(),

  checkoutBaskets: [],
  discounts: [],
  giftcards: [],

  state: 0,
};

export const defaultCheckoutInstanceSettings = {
  instanceId: "",
  isGiftcardEnabled: false,
  isDiscountEnabled: false,
  guestFutureOrderDates: 0,
  chainDeliveryEnabled: false,
  orderTimeInterval: 15,
};

export const defaultCheckoutSession = {
  bringOwnBagIsChecked: false,
  showValidationErrors: false,
  isDelivery: false,

  chosenDate: null,
  chosenTimeslot: null,

  guestInformation: {
    name: "",
    phone: "",
    email: "",
  },

  delivery: null,

  discount: null,
  giftcard: null,
};
