import { IBasketItem, ILabel, IProduct } from "@crunchit/types";
import { useTranslation } from "react-i18next";

import Labels from "../Labels";
import Counter from "components/ui/Counter";
import useTranslationText from "hooks/useTranslationText";
import { useBasketSelector } from "store/basket";

interface IProductDetailsProps {
  product: IProduct;
  isExpanded: boolean;
  labels?: ILabel[];
  toggleModifiers: () => void;
  countChanged: (newCount: number) => void;
}

export default function ProductDetails(props: IProductDetailsProps) {
  const { product, isExpanded, labels } = props;
  const { countChanged, toggleModifiers } = props;

  const { t } = useTranslation();
  const { isLoading: basketIsLoading, basket } = useBasketSelector();
  const { getTranslationText } = useTranslationText();

  const showModifierToggleButton = product.modifiers.length > 0;

  let productLabels: ILabel[] = [];

  if (product.labelIds && labels) {
    product.labelIds.forEach((labelId) => {
      const label = labels.find((l) => l.id === labelId);

      if (label) {
        productLabels.push(label);
      }
    });
  }

  function currentItemCount(productId: number) {
    let count = 0;

    if (basket) {
      const findInCart = basket.items.find((prod: IBasketItem) => prod.productId === productId);

      if (findInCart) {
        count = findInCart.amount;
      }
    }

    return count;
  }

  return (
    <div className="product-details">
      <div className="product-name-container" onClick={toggleModifiers}>
        <h2 className="product-name">{getTranslationText(product.name)}</h2>

        {productLabels.length > 0 && <Labels labels={productLabels} />}

        <p className="product-description small-text">{getTranslationText(product.description)}</p>
      </div>

      <div className={`product-action-container collapsible-section ${isExpanded ? "expanded" : "collapsed"}`}>
        <h3 className="product-price">
          {product.price}
          <span className="product-price-text">{t("common:Currency")}</span>
        </h3>

        {showModifierToggleButton && (
          <button className="modifier-button bold-text" onClick={toggleModifiers} id={product.id.toString()}>
            {t("products:ModifierTitle")}
            <span className="collapsible-icon"></span>
          </button>
        )}

        {!showModifierToggleButton && (
          <div className="counter-container">
            <Counter count={currentItemCount(product.id)} isDisabled={basketIsLoading} handleChange={countChanged} />
          </div>
        )}
      </div>
    </div>
  );
}
