import { IPayment, PaymentType } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./ItemizedTotal.scss";

interface IItemizedTotalProps {
  orderTotal: number;

  payments?: Partial<IPayment>[];

  deliveryFee?: number;
  test?: number;

  isDiscountEnabled?: boolean;
  discountAmount?: number;
}

export default function ItemizedTotal(props: IItemizedTotalProps) {
  const { orderTotal = 0, isDiscountEnabled = false, discountAmount = 0, deliveryFee = 0, payments = [] } = props;

  const { t } = useTranslation();

  // Total for only the products
  const orderItemsTotal = orderTotal + discountAmount - deliveryFee;

  const showDiscount = useMemo(() => isDiscountEnabled, [isDiscountEnabled]);
  const showDeliveryFee = useMemo(() => deliveryFee > 0, [deliveryFee]);
  const showPayments = useMemo(() => payments.findIndex((p) => p.paymentId) > -1, [payments]);

  // Only showing itemizing if we have more than an order total
  let showItemized = useMemo(() => {
    return showDiscount || showDeliveryFee || showPayments || orderItemsTotal !== orderTotal;
  }, [showDiscount, showDeliveryFee, showPayments, orderItemsTotal, orderTotal]);

  let paidAmount = useMemo(() => {
    let paid = 0;
    if (payments.length) {
      paid = payments.reduce((accumulated, currentPayment) => {
        const { type, amount = 0 } = currentPayment;
        return accumulated + (type && type === PaymentType.UNPAID ? 0 : amount);
      }, 0);
    }
    return paid;
  }, [payments]);

  return (
    <div className="itemized-total-section">
      {showItemized && (
        <div className="total-items">
          <div className="total-item">
            <p className="total-item-left">{t("cart:OrderSummary.Value")}</p>
            <p className="total-item-right">
              {formatPrice(orderItemsTotal)} {t("common:Currency")}
            </p>
          </div>

          {showDiscount && (
            <div className="total-item">
              <p className="total-item-left">{t(discountAmount > 0 ? "cart:OrderSummary.Discount" : "cart:OrderSummary.OptionalDiscount")}</p>
              <p className="total-item-right">
                - {formatPrice(discountAmount)} {t("common:Currency")}
              </p>
            </div>
          )}
          {showDeliveryFee && (
            <div className="total-item">
              <p className="total-item-left">{t("cart:OrderSummary.DeliveryFee")}</p>
              <p className="total-item-right">
                {formatPrice(deliveryFee)} {t("common:Currency")}
              </p>
            </div>
          )}

          {showPayments &&
            payments &&
            payments.map(function (payment) {
              return payment.paymentId !== undefined && payment.amount ? (
                <div key={payment.paymentId} className="total-item">
                  <p className="total-item-left">{t(payment.type === PaymentType.GIFTCARD ? "cart:OrderSummary.Giftcard" : "cart:OrderSummary.OnlinePayment")}</p>
                  <p className="total-item-right">
                    - {formatPrice(payment.amount)} {t("common:Currency")}
                  </p>
                </div>
              ) : null;
            })}
        </div>
      )}

      <div className="final-total simple-total">
        <h2 className="total-title">{t("cart:OrderSummary.Leftover")}</h2>
        <h2 className="total-amount">
          {formatPrice(orderTotal - paidAmount)} <span className="currency">{t("common:Currency")}</span>
        </h2>
      </div>
    </div>
  );
}
