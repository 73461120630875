import { ICategory } from "@crunchit/types";
import useTranslationText from "hooks/useTranslationText";
import "./CategoryGrid.scss";

interface ICategoryGridProps {
  categories: ICategory[];
  handleCategoryClick: (categoryId: number) => void;
}

export default function CategoryGrid(props: ICategoryGridProps) {
  const { categories } = props;
  const { handleCategoryClick } = props;

  const { getTranslationText } = useTranslationText();

  return (
    <div className="category-grid">
      {categories.map(function (category) {
        return (
          <div key={category.id} className="gridbox" onClick={() => handleCategoryClick(category.id)}>
            {category.imageUrl && <div className="gridbox-image" style={{ backgroundImage: "url(" + category.imageUrl + ")" }}></div>}

            <h3 className="gridbox-title">{getTranslationText(category.name)}</h3>
          </div>
        );
      })}
    </div>
  );
}
