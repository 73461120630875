import { ICheckout, ICheckoutInstanceSettings, ICheckoutSession } from "@crunchit/types";
import { defaultCheckout, defaultCheckoutInstanceSettings, defaultCheckoutSession } from "./defaults";

export const checkoutFactory = (checkout: Partial<ICheckout> | null = {}): ICheckout => {
  return {
    ...defaultCheckout,
    ...checkout,
  };
};

export const checkoutInstanceSettingsFactory = (checkoutInstanceSettings: Partial<ICheckoutInstanceSettings> | null = {}): ICheckoutInstanceSettings => {
  return {
    ...defaultCheckoutInstanceSettings,
    ...checkoutInstanceSettings,
  };
};

export const checkoutSessionFactory = (checkoutSession: Partial<ICheckoutSession> | null = {}): ICheckoutSession => {
  return {
    ...defaultCheckoutSession,
    ...checkoutSession,
  };
};
