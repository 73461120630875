import { IOption, IOptionValue } from "models/input";
import { ChangeEvent, useMemo } from "react";
import "./Select.scss";

interface ISelectProps {
  options: IOption[];
  currentValue?: IOptionValue;
  disabledOptionText?: string;
  overrideParameters: {
    name: string;
    disabled?: boolean;
  };
  inputChanged: (value: IOptionValue) => void;
}

export default function Select(props: ISelectProps) {
  const { options, currentValue, disabledOptionText, overrideParameters } = props;
  const { inputChanged } = props;

  const emptyValue = "none";
  const value = useMemo(() => (currentValue !== undefined ? currentValue : emptyValue), [currentValue]);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;

    if (target) {
      inputChanged(target.value);
    }
  };

  return (
    <div className="input-item">
      <select className={value === emptyValue ? "empty" : ""} value={value} {...overrideParameters} onChange={handleChange}>
        {disabledOptionText && (
          <option value={emptyValue} disabled hidden>
            {disabledOptionText}
          </option>
        )}

        {options.map((o) => (
          <option value={o.value} key={o.value}>
            {o.text}
          </option>
        ))}
      </select>
    </div>
  );
}
