import { createAsyncThunk } from "@reduxjs/toolkit";
import { IBasket, IBasketChoiceToAdd, IBasketItem, IBasketProductRequestBody, ICriticalError, IProduct, siteStorageName } from "@crunchit/types";
import { clearAllLocalStorage, getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "@crunchit/utilities";

import BasketService from "services/BasketService";
import { isExpired } from "./utils/helpers";

type Rejection = { rejectValue: ICriticalError };
type AddBasketItemParams = { basketId: string; product: IProduct; count: number; choices?: IBasketChoiceToAdd[] };
type UpdateBasketItemParams = { basketId: string; basketProduct: IBasketItem; updatedCount: number };
type RemoveBasketItemParams = { basketId: string; basketProductId: string };

/* Async reducers */

export const loadBasket = createAsyncThunk<IBasket | null, void, Rejection>("basket/loadBasket", async (args, { rejectWithValue }) => {
  const basketId = getFromLocalStorage(siteStorageName, "basketId");
  let basket = null;

  if (basketId) {
    try {
      const basketResponse = await BasketService.getBasketById(basketId);

      if (basketResponse.isSuccess() && !isExpired(basketResponse.data.createdTimeUtc)) {
        return basketResponse.data;
      }
    } catch (error) {
      return rejectWithValue({ message: "Unable to load basket", error: error instanceof Error ? error.message : error });
    }

    // Basket not found (or expired) - remove it
    removeFromLocalStorage(siteStorageName, "basketId");
    removeFromLocalStorage(siteStorageName, "checkoutId");
  }

  return basket;
});

export const addBasketItem = createAsyncThunk<IBasket | null, AddBasketItemParams, Rejection>("basket/addBasketItem", async ({ basketId, product, count, choices }, { rejectWithValue }) => {
  const productToAdd: IBasketProductRequestBody = {
    amount: count,
    productId: product.id,
    price: product.price,
    productPrice: product.price,
    imageUrl: product.imageUrl,
    choices: choices ? choices : [],
  };

  let finalBasketId;

  if (basketId) {
    finalBasketId = basketId;
  } else {
    try {
      const newBasketResponse = await BasketService.getNewBasket();

      if (!newBasketResponse.isSuccess()) {
        return rejectWithValue({ message: "Unable to get new basket", error: newBasketResponse.errors });
      }

      finalBasketId = newBasketResponse.data.id;
      saveToLocalStorage(siteStorageName, "basketId", finalBasketId);
    } catch (error) {
      return rejectWithValue({ message: "Unable to get new basket", error: error instanceof Error ? error.message : error });
    }
  }

  try {
    const basketResponse = await BasketService.addProductToBasket(finalBasketId, productToAdd);

    if (!basketResponse.isSuccess()) {
      return rejectWithValue({ message: "Unable to add item to basket", error: basketResponse.errors });
    }

    return basketResponse.data;
  } catch (error) {
    return rejectWithValue({ message: "Unable to add item to basket", error: error instanceof Error ? error.message : error });
  }
});

export const updateBasketItem = createAsyncThunk<IBasket, UpdateBasketItemParams, Rejection>("basket/updateBasketItem", async ({ basketId, basketProduct, updatedCount }, { rejectWithValue }) => {
  let updatedBasketProduct = Object.assign({}, basketProduct);
  updatedBasketProduct.amount = updatedCount;

  try {
    const basketResponse = await BasketService.updateBasketItem(basketId, basketProduct.id, updatedBasketProduct);

    if (!basketResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to update basket with item '${basketProduct.id}'`, error: basketResponse.errors });
    }

    return basketResponse.data;
  } catch (error) {
    return rejectWithValue({ message: `Unable to update basket with item '${basketProduct.id}'`, error: error instanceof Error ? error.message : error });
  }
});

export const removeBasketItem = createAsyncThunk<IBasket, RemoveBasketItemParams, Rejection>("basket/removeBasketItem", async ({ basketId, basketProductId }, { rejectWithValue }) => {
  try {
    const basketResponse = await BasketService.removeBasketItem(basketId, basketProductId);

    if (!basketResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to remove basket item '${basketProductId}'`, error: basketResponse.errors });
    }

    return basketResponse.data;
  } catch (error) {
    return rejectWithValue({ message: `Unable to remove basket item '${basketProductId}'`, error: error instanceof Error ? error.message : error });
  }
});

export const clearBasket = createAsyncThunk("basket/clearBasket", async () => {
  // We don't delete the basket - just removing our local reference to it
  clearAllLocalStorage(siteStorageName);
  return true;
});

const thunks = {
  loadBasket,
  addBasketItem,
  updateBasketItem,
  removeBasketItem,
  clearBasket,
};

export default thunks;
