import { getFromLocalStorage } from "@crunchit/utilities";
import { ICheckoutSectionProps, siteStorageName } from "@crunchit/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SectionWrapper from "./SectionWrapper";
import { Input } from "components/ui/forms";
import Select from "components/ui/forms/Select";
import { useInputValidation } from "hooks/useInputValidation";
import { IOption, IOptionValue } from "models/input";
import { useAppSelector } from "store/app";
import { useCheckoutSelector, checkoutActions } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import { roomNumberKey, tableNumberKey } from "utils/constants";

export default function GuestInfo(props: ICheckoutSectionProps) {
  const { index } = props;

  const { appSettings, isRoomService, isOrderAtTAble } = useAppSelector();
  const { t } = useTranslation();
  const { checkoutSession } = useCheckoutSelector();
  const { getInputError } = useInputValidation();
  const dispatch = useCustomDispatch();

  const { name = "", phone = "", email = "", amountOfGuests = undefined } = checkoutSession.guestInformation;
  const [guestOptions, setGuestOptions] = useState<IOption[]>([]);
  const [roomNumber, setRoomNumber] = useState<string>();
  const [tableNumber, setTableNumber] = useState<string>();

  const nameError = useMemo(() => getInputError("name", name, "errors:Input.Name"), [name, getInputError]);
  const phoneError = useMemo(() => getInputError("phone", phone, "errors:Input.Phone"), [phone, getInputError]);
  const emailError = useMemo(() => getInputError("email", email, "errors:Input.Email"), [email, getInputError]);

  function handleInputChange(change: { value: string; name: string }) {
    const { value, name } = change;

    let updatedGuestInfo = Object.assign({}, checkoutSession.guestInformation);

    switch (name) {
      case "name":
        updatedGuestInfo.name = value;
        break;
      case "phone":
        updatedGuestInfo.phone = value;
        break;
      case "email":
        updatedGuestInfo.email = value;
        break;
      default:
        break;
    }

    dispatch(checkoutActions.setCheckoutSessionGuestInfo(updatedGuestInfo));
  }

  function handleSelectChange(change: IOptionValue) {
    dispatch(checkoutActions.setCheckoutSessionGuestInfo({ ...checkoutSession.guestInformation, amountOfGuests: change as number | undefined }));
  }

  useEffect(() => {
    if (appSettings.checkoutGuests) {
      const options = [];

      for (let i = 0; i < 21; i++) {
        options.push({ text: t("checkout:GuestInfo.AmountOfGuests.guest", { count: i }), value: i });
      }

      setGuestOptions(options);
    }
  }, [appSettings.checkoutGuests, t]);

  useEffect(() => {
    if (isRoomService) {
      setRoomNumber(getFromLocalStorage(siteStorageName, roomNumberKey));
    }
  }, [isRoomService]);

  useEffect(() => {
    if (isOrderAtTAble) {
      setTableNumber(getFromLocalStorage(siteStorageName, tableNumberKey));
    }
  }, [isOrderAtTAble]);

  useEffect(() => {
    dispatch(checkoutActions.setCheckoutSessionGuestInfo({ ...checkoutSession.guestInformation, tableName: roomNumber }));
  }, [roomNumber, dispatch]); // eslint-disable-line

  useEffect(() => {
    dispatch(checkoutActions.setCheckoutSessionGuestInfo({ ...checkoutSession.guestInformation, tableName: tableNumber }));
  }, [tableNumber, dispatch]); // eslint-disable-line

  return (
    <SectionWrapper index={index} title={t("checkout:GuestInfo.Title")}>
      <div className="input-form">
        {roomNumber && <Input inputValue={roomNumber} label={t("checkout:GuestInfo.InputRoomNumber")} overrideParameters={{ type: "text", name: "room", disabled: true }} />}

        {tableNumber && <Input inputValue={tableNumber} label={t("checkout:GuestInfo.InputTableNumber")} overrideParameters={{ type: "text", name: "room", disabled: true }} />}

        <Input inputValue={name} label={t("checkout:GuestInfo.InputName")} errorMessage={nameError ? t(nameError) : ""} overrideParameters={{ type: "text", name: "name" }} inputChanged={handleInputChange} />

        <Input inputValue={phone} label={t("checkout:GuestInfo.InputPhone")} errorMessage={phoneError ? t(phoneError) : ""} overrideParameters={{ type: "tel", name: "phone" }} inputChanged={handleInputChange} />

        <Input inputValue={email} label={t("checkout:GuestInfo.InputEmail")} errorMessage={emailError ? t(emailError) : ""} overrideParameters={{ type: "email", name: "email" }} inputChanged={handleInputChange} />

        {appSettings.checkoutGuests && <Select currentValue={amountOfGuests} options={guestOptions} overrideParameters={{ name: "guests" }} disabledOptionText={t("checkout:GuestInfo.AmountOfGuests.Placeholder")} inputChanged={handleSelectChange} />}
      </div>
    </SectionWrapper>
  );
}
