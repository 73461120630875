import { useEffect } from "react";
import { useNavigate } from "react-router";

import Header from "components/navigation/Header";
import Spinner from "components/ui/Spinner";

import useAppState from "hooks/useAppState";
import useAppRedirects from "hooks/useAppRedirects";
import { IViewProps } from "models/layout";
import { useAppSelector } from "store/app";

// Performing all page checks necessary before loading content
export default function View(props: IViewProps) {
  const { pageKey, layoutOptions, children } = props;
  const { isStatusPage = false, hideBasket = false, hideSidebar = false } = layoutOptions || {};

  const { isError, isLoading, loadingMessageKey } = useAppState();
  const { isFasttrack, appSettings } = useAppSelector();

  const redirect = useNavigate();
  const redirectPath = useAppRedirects({ isError, isLoading });

  useEffect(() => {
    if (redirectPath) {
      redirect(redirectPath);
    }
  }, [redirectPath, redirect]);

  useEffect(() => {
    if (appSettings.backgroundImage) {
      const rootElement = document.getElementById("root");

      if (rootElement) {
        rootElement.style.backgroundImage = `url("${appSettings.backgroundImage}")`;
      }
    }
  }, [appSettings.backgroundImage]);

  useEffect(() => {
    if (appSettings.stylingClass) {
      const rootElement = document.getElementById("root");

      if (rootElement) {
        rootElement.classList.add(appSettings.stylingClass.split(" ")[0]);
      }
    }
  }, [appSettings.stylingClass]);

  return (
    <div className={`page-view ${isStatusPage ? "status-page" : ""} ${pageKey}-page ${isFasttrack ? "fasttrack" : ""}`}>
      <Header {...{ hideBasket, hideSidebar }} />

      <div className="page-view-content inner">
        {/* Content from each component */}
        {children}
      </div>

      {isLoading && <Spinner loadingMessageKey={loadingMessageKey} />}
    </div>
  );
}
