import { IFrontendSettings, IModule } from "@crunchit/types";
import { IAppSettings } from "models/app";
import { navigationSettings, orderSettings } from "settings";
import getCheckoutSettings from "settings/checkout";

export const defaultModule: IModule = {
  id: "",

  menuInstanceId: "",
  productInstanceId: "",
  checkoutInstanceId: "",

  capacityInstanceId: null,
  deliveryInstanceId: null,
  discountInstanceId: null,
  giftcardInstanceId: null,
  lavuInstanceId: null,
  paymentInstanceId: null,
  placeholderInstanceId: null,
  posSettingInstanceId: null,

  name: "",
  type: 0,
  productionUnitId: 0,

  isActive: true,
  isVisible: true,
};

export const defaultFrontendSettings: IFrontendSettings = {
  showClosedFrontpageMessage: false,

  onlinePayment: false,
  mobilepay: false,
  payAtPickup: false,

  startCategory: undefined,
  precheckoutCategory: undefined,
  bagFeeItem: undefined,
};

export const defaultAppSettings: IAppSettings = {
  smileyUrl: "",
  logo: "",
  deliveryEnabled: false,

  ...getCheckoutSettings(),
  ...navigationSettings,
  ...orderSettings,

  ...defaultFrontendSettings,
};
