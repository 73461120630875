import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./PaymentError.scss";

interface IPaymentErrorProps {
  showRestaurantOfflineError: boolean;
  missingFields: string[];
}

export default function PaymentError(props: IPaymentErrorProps) {
  const { showRestaurantOfflineError, missingFields } = props;
  const { t } = useTranslation();

  const missingFieldsErrorMessage = useMemo(() => {
    if (missingFields && missingFields.length > 0) {
      let errorMsg = t("errors:MissingFields.Preface");
      let i = 0;

      while (i < missingFields.length) {
        let preface = i === 0 ? "" : ",";

        // This is the last one, but not the only one
        if (i > 0 && !missingFields[i + 1]) {
          preface = " &";
        }

        const str = preface + " " + t(missingFields[i]);
        errorMsg += str;
        i++;
      }

      return errorMsg;
    } else {
      return t("errors:MissingFields.Generic");
    }
  }, [t, missingFields]);

  return (
    <div className="checkout-error-container">
      {missingFields.length > 0 && (
        <div className="checkout-error">
          <p>{missingFieldsErrorMessage}</p>
        </div>
      )}

      {showRestaurantOfflineError && (
        <div className="checkout-error">
          <p>{t("errors:Payment.RestaurantOffline")}</p>
        </div>
      )}
    </div>
  );
}
