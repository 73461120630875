import { useCallback, useMemo, useState } from "react";

import withClickOutside from "hoc/withClickOutside";
import { ILayoutOptions } from "models/layout";
import Header from "./Header";
import useHeaderNavigation from "./useHeaderNavigation";

export default function HeaderContainer(props: Partial<ILayoutOptions>) {
  const { hideSidebar, hideBasket } = props;

  const { hideNavigation, basketIsOpen, sidebarIsOpen, clickHandlers: navigationClickHandlers, collapseAllNavigation } = useHeaderNavigation();

  const [moreIsOpen, setMoreIsOpen] = useState(false);

  const handleClickOutside = useCallback(
    (event: Event) => {
      const eventTarget = event.target as Element;
      const isClickAllowed = eventTarget.className.indexOf("click-allowed") > -1;

      if (!isClickAllowed) {
        collapseAllNavigation();
        setMoreIsOpen(false);
      }
    },
    [collapseAllNavigation]
  );

  const handleMoreClick = useCallback(() => {
    setMoreIsOpen(!moreIsOpen);
  }, [moreIsOpen]);

  let HeaderComponent = useMemo(() => withClickOutside(Header, { clickHandler: handleClickOutside }), [handleClickOutside]);

  const clickHandlers = {
    ...navigationClickHandlers,
    more: handleMoreClick,
  };

  return <HeaderComponent hideBasket={hideNavigation || hideBasket} hideSidebar={hideNavigation || hideSidebar} basketIsOpen={basketIsOpen} sidebarIsOpen={sidebarIsOpen} moreIsOpen={moreIsOpen} clickHandlers={clickHandlers} />;
}
