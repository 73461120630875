import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { useAppSelector } from "store/app";

interface IRedirectHookProps {
  isError: boolean;
  isLoading: boolean;
}

export default function useAppRedirects(props: IRedirectHookProps) {
  const { isError, isLoading } = props;

  const { token, appSettings, isDownForMaintenance } = useAppSelector();
  const { pathname } = useLocation();

  let [redirectPath, setRedirectPath] = useState("");

  // Redirects based on global redux states

  useEffect(() => {
    if (isLoading === false && !token && pathname.indexOf("notfound") === -1) {
      // Loading finished and we don't have data - show error
      setRedirectPath("/notfound");
    }
  }, [isLoading, token, pathname]);

  useEffect(() => {
    if (isError && pathname.indexOf("error") === -1) {
      // Global state dictates when we show an error page
      setRedirectPath("/error");
    }
  }, [isError, pathname]);

  useEffect(() => {
    if (isDownForMaintenance && pathname.indexOf("maintenance") === -1) {
      // Maintenance mode
      setRedirectPath("/maintenance");
    }
  }, [isDownForMaintenance, pathname]);

  useEffect(() => {
    if (appSettings.showClosedFrontpageMessage && pathname !== "/" && pathname.indexOf("order") === -1) {
      // Temporarily closed
      setRedirectPath("/");
    }
  }, [appSettings, pathname]);

  useEffect(() => {
    // Redirecting frontpage to category if set
    if (pathname === "/" && appSettings.startCategory) {
      setRedirectPath("/menu/" + appSettings.startCategory);
    }
  }, [appSettings, pathname]);

  // Everytime the path changes, we reset the redirect
  useEffect(() => {
    setRedirectPath("");
  }, [pathname]);

  return redirectPath;
}
