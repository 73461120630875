import { useCallback, useState } from "react";
import { isElementInViewport } from "utils/helpers/view";

export default function useActiveModifier() {
  let [activeModifierIndex, setActiveModifierIndex] = useState(0);

  const updateActiveModifierIndex = useCallback((updatedActiveModifierIndex: number, modifierId: number) => {
    setActiveModifierIndex(updatedActiveModifierIndex);

    // Check if the current modifier title is in view - otherwise scroll
    const element = document.getElementById(modifierId.toString());
    if (element) {
      let rect = element.getBoundingClientRect();
      if (!isElementInViewport(element)) {
        let top = rect.top;
        window.scrollBy({ top: top - 85, behavior: "smooth" });
      }
    }
  }, []);

  const collapseModifier = () => setActiveModifierIndex(-1);
  const expandModifier = (modifierIndex: number) => setActiveModifierIndex(modifierIndex);

  return {
    activeModifierIndex,
    updateActiveModifierIndex,
    collapseModifier,
    expandModifier,
  };
}
