import { useTranslation } from "react-i18next";
import SectionWrapper from "./SectionWrapper";
import { useAppSelector } from "store/app";
import "./RoomServiceMessage.scss";

export default function RoomServiceMessage() {
  const { isRoomService } = useAppSelector();

  const { t } = useTranslation();

  if (!isRoomService) {
    return <></>;
  }

  return (
    <SectionWrapper>
      <p className="room-service-message oasia">{t("checkout:RoomServiceMessage.Oasia")}</p>
      <p className="room-service-message jacobsen">{t("checkout:RoomServiceMessage.Jacobsen")}</p>
      <p className="room-service-message bistecca">{t("checkout:RoomServiceMessage.Bistecca")}</p>
      <p className="room-service-message shop">{t("checkout:RoomServiceMessage.Shop")}</p>
      <p className="room-service-message ginbar">{t("checkout:RoomServiceMessage.Ginbar")}</p>
    </SectionWrapper>
  );
}
