import { IFrontendSettings, IModule } from "@crunchit/types";
import { defaultAppSettings, defaultFrontendSettings, defaultModule } from "./defaults";
import { IAppSettings } from "models/app";

export const moduleFactory = (module: Partial<IModule> | null = {}): IModule => {
  return {
    ...defaultModule,
    ...module,
  };
};

export const frontendSettingsFactory = (frontendSettings: Partial<IFrontendSettings> | null = {}): IFrontendSettings => {
  return {
    ...defaultFrontendSettings,
    ...frontendSettings,
  };
};

export const appSettingsFactory = (appSettings: Partial<IAppSettings> | null = {}): IAppSettings => {
  return {
    ...defaultAppSettings,
    ...appSettings,
  };
};
