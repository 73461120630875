import { get } from "@crunchit/http-client";
import { IOrder } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_ORDER_URI || `order.${getEnvironment()}crunchy.ninja/api`;

const OrderService = {
  getOrderById: async (id: string) => {
    return get<IOrder>(`${api}/order/${id}`, getToken());
  },
};

export default OrderService;
