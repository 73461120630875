import { createRef, useEffect, useCallback, ComponentType } from "react";

// Wrapper for detecting clicks outside component
export default function withClickOutside<TProps extends {}>(WrappedComponent: ComponentType<TProps>, handler: { clickHandler: (event: Event) => void }) {
  return function (props: TProps) {
    let wrapperRef = createRef<HTMLInputElement>();

    const handleClickOutside = useCallback(
      (event: Event) => {
        if (wrapperRef) {
          const eventTarget = event.target as Element;
          const isRefElement = wrapperRef.current && wrapperRef.current.contains(eventTarget);

          if (!isRefElement) {
            handler.clickHandler(event);
          }
        }
        return true;
      },
      [wrapperRef]
    );

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleClickOutside]);

    return (
      <div className="click-outside-wrapper" ref={wrapperRef}>
        <WrappedComponent {...props} />
      </div>
    );
  };
}
