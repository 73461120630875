import { useCallback } from "react";
import ProductService from "services/ProductService";
import { basketThunks, useBasketSelector } from "store/basket";
import { useCustomDispatch } from "store/useStore";

export default function useCheckoutFees() {
  const { basket, basketProducts } = useBasketSelector({ allowCartFees: true });
  const dispatch = useCustomDispatch();

  const loadFee = useCallback(async (productId: number, productInstanceId: string) => {
    const response = await ProductService.getProductById(productId, productInstanceId);

    if (response.isSuccess()) {
      return response.data;
    }
  }, []);

  const initializeFee = useCallback(
    async (productId: number, shouldBeInCart: boolean, productInstanceId: string) => {
      const feeCartItem = basketProducts.find((p) => p.productId === productId);

      if (feeCartItem) {
        if (!shouldBeInCart) {
          dispatch(basketThunks.removeBasketItem({ basketId: basket.id, basketProductId: feeCartItem.id }));
        }
      } else if (shouldBeInCart) {
        const feeProduct = await loadFee(productId, productInstanceId);

        if (feeProduct) {
          dispatch(basketThunks.addBasketItem({ basketId: basket.id, product: feeProduct, count: 1 }));
        }
      }
    },
    [basket.id, basketProducts, dispatch, loadFee]
  );

  return {
    initializeFee,
  };
}
