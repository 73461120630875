import { ReactNode } from "react";

import "./Checkbox.scss";

interface ICheckboxProps {
  checked: boolean;
  label: string | ReactNode;
  handleInputChange: (checked: boolean) => void;
}

export default function Checkbox(props: ICheckboxProps) {
  const { checked, label, handleInputChange } = props;

  return (
    <div className="full checkbox-container">
      <label className="full checkbox-label">
        <input type="checkbox" className="checkbox" checked={checked} onChange={() => handleInputChange(!checked)} />
        {label}
      </label>
    </div>
  );
}
