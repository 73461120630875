import { useCustomDispatch } from "store/useStore";
import { basketThunks } from "store/basket";

import FullBasket from "./FullBasket";
import MiniBasket from "./MiniBasket";

interface IBasketProps {
  isMini?: boolean; // Basket is either in a mini state or fully expanded
  handleClick: () => void; // Click is triggered from the appropriate basket, determined by isMini
}

export default function Basket(props: IBasketProps) {
  const { isMini, handleClick } = props;
  const dispatch = useCustomDispatch();

  return (
    <>
      {isMini ?
        <MiniBasket handleClick={handleClick} />

        :

        <FullBasket
          clickHandlers={{
            clearBasket: () => dispatch(basketThunks.clearBasket()),
            continue: handleClick
          }}
        />
      }
    </>
  );
}
