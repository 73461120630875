import { IProduct } from "@crunchit/types";
import { isBlockedProduct } from "@crunchit/utilities";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";
import { useBasketSelector } from "store/basket";

import useProducts from "hooks/useProducts";
import useTranslationText from "hooks/useTranslationText";

import PageTitle from "components/ui/PageTitle";
import Sidebar from "components/navigation/Sidebar";
import Product from "components/menu/Product";
import SimpleTotal from "components/basket/totals/SimpleTotal";

import "./Precheckout.scss";

export default function Precheckout() {
  const { appIsInitialized, appSettings } = useAppSelector();
  const { precheckoutCategory, isLoading: menuDataIsLoading } = useMenuSelector();
  const { basketProductTotal } = useBasketSelector();
  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();

  const redirect = useNavigate();

  let {
    context,

    blockedProducts,
    blockedChoices,

    loadProducts,
    loadWarehouse,

    getAvailableProductIds,
  } = useProducts();

  useEffect(() => {
    if (appIsInitialized) {
      if (appSettings.precheckoutCategory) {
        loadWarehouse();
      } else {
        redirect("/checkout");
      }
    }
  }, [appIsInitialized, appSettings.precheckoutCategory, loadWarehouse, redirect]);

  useEffect(() => {
    if (precheckoutCategory) {
      if (precheckoutCategory.products.length > 0) {
        const precheckoutProductIds = precheckoutCategory.products.map((p) => p.productId);
        const availableProducts = getAvailableProductIds(precheckoutProductIds);

        if (availableProducts.length === 0) {
          redirect("/checkout");
        }
      } else {
        redirect("/checkout");
      }
    }
  }, [precheckoutCategory, getAvailableProductIds, redirect]);

  useEffect(() => {
    loadProducts(precheckoutCategory ? precheckoutCategory.products : []);
  }, [appSettings.precheckoutCategory, precheckoutCategory, loadProducts]);

  // Not allowing modifiers in precheckout
  let precheckoutProducts = useMemo(() => {
    return context.products.map((p: IProduct) => {
      let updatedProduct = Object.assign({}, p);
      updatedProduct.modifiers = [];
      return updatedProduct;
    });
  }, [context.products]);

  async function handleContinueClick() {
    redirect("/checkout");
  }

  return (
    <>
      {precheckoutCategory !== undefined && (
        <>
          <PageTitle title={getTranslationText(precheckoutCategory.name)} subtitle={getTranslationText(precheckoutCategory.description)} />

          <div className="content-body sidebar-body">
            <Sidebar />

            <div className="precheckout-content">
              <div className="precheckout-section top-div">
                <h2 className="precheckout-subtitle">{t("precheckout:ContinueText")}</h2>
                <button className="button accent-button" onClick={handleContinueClick}>
                  {t("common:Navigation.Continue")}
                </button>
              </div>

              <div className="precheckout-section bottom-div">
                <h2 className="precheckout-subtitle">
                  <span>{t("precheckout:AddItemsText.FirstText")} </span>
                  {t("precheckout:AddItemsText.SecondText")}
                </h2>

                <div className="precheckout-products">
                  {!menuDataIsLoading &&
                    precheckoutProducts.map(function (product: IProduct) {
                      return <Product key={product.id} product={product} context={context} isBlockedProduct={isBlockedProduct(product.id, blockedProducts)} blockedChoices={blockedChoices} />;
                    })}
                </div>

                <SimpleTotal total={basketProductTotal} />
              </div>
              <div className="precheckout-section precheckout-footer">
                <button className="button continue-button" onClick={handleContinueClick}>
                  {t("common:Navigation.Continue")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
