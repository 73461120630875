import { useTranslation } from "react-i18next";

import { useCheckoutSelector } from "store/checkout";
import "./DeliveryMethodPicker.scss";

interface IDeliveryMethodPickerProps {
  deliveryIsDisabled: boolean;
  handleDeliveryMethodUpdate: (updatedIsDelivery: { isDelivery: boolean }) => void;
}

export default function DeliveryMethodPicker(props: IDeliveryMethodPickerProps) {
  const { deliveryIsDisabled, handleDeliveryMethodUpdate } = props;

  const { checkoutSession } = useCheckoutSelector();
  const { isDelivery } = checkoutSession;
  const { t } = useTranslation();

  function handleClick(updatedIsDelivery: boolean) {
    handleDeliveryMethodUpdate({ isDelivery: updatedIsDelivery });
  }

  return (
    <div className="delivery-picker">
      <div className={`delivery-picker-option option-pickup ${isDelivery ? "" : "active"}`} onClick={() => handleClick(false)}>
        <p className="option-text">{t("checkout:DeliveryMethod.PickupTitle")}</p>
      </div>

      <div className={`delivery-picker-option option-delivery ${isDelivery ? "active" : ""}`} onClick={() => (deliveryIsDisabled ? null : handleClick(true))}>
        <p className="option-text">{t("checkout:DeliveryMethod.DeliveryTitle")}</p>

        {deliveryIsDisabled && <p className="delivery-disabled-text">{t("errors:DeliveryMethod.NotAllowedGiftcard")}</p>}
      </div>
    </div>
  );
}
