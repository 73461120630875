import { useNavigate, useParams } from "react-router";
import useTranslationText from "hooks/useTranslationText";
import { useMenuSelector } from "store/menu";
import "./Sidebar.scss";

interface ISidebarProps {
  handleCategoryClick?: (categoryId: number) => void;
}

export default function Sidebar(props: ISidebarProps) {
  const { handleCategoryClick } = props;

  const { categories } = useMenuSelector();
  const { categoryId } = useParams();
  const { getTranslationText } = useTranslationText();
  
  const redirect = useNavigate();

  function handleClick(chosenCategoryId: number) {
    handleCategoryClick ? handleCategoryClick(chosenCategoryId) : redirect(`/menu/${chosenCategoryId}`);
  }

  const activeCategoryId: number = categoryId ? parseInt(categoryId) : 0;

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        {categories.map(function (category) {
          return (
            <p key={category.id} onClick={() => handleClick(category.id)} className={`sidebar-item ${activeCategoryId === category.id ? "active" : ""}`}>
              <span className="dot"></span> {getTranslationText(category.name)}
            </p>
          );
        })}
      </div>
    </div>
  );
}
