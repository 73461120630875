import { ILayoutOptions } from "models/layout";
import Topbar from "components/navigation/Topbar";
import Sidebar from "components/navigation/Sidebar";
import Basket from "components/basket/Basket";
import Smiley from "components/ui/smiley/Smiley";
import FasttrackIndicator from "../FasttrackIndicator";

import "./Header.scss";

interface IHeaderProps extends Partial<ILayoutOptions> {
  basketIsOpen: boolean;
  sidebarIsOpen: boolean;
  moreIsOpen: boolean;

  clickHandlers: {
    sidebar: () => void;
    sidebarCategory: (categoryId: number) => void;
    logo: () => void;
    basket: () => void;
    continue: () => void;
    more: () => void;
  };
}

// A wrapper including the topbar header and expandable cart and navigation
export default function Header(props: IHeaderProps) {
  const { hideSidebar, hideBasket, basketIsOpen, sidebarIsOpen, moreIsOpen, clickHandlers } = props;

  return (
    <div className={`header-container ${basketIsOpen || sidebarIsOpen ? "expanded-header-container" : ""}`}>
      <FasttrackIndicator />

      <Topbar hideBasket={hideBasket} hideSidebar={hideSidebar} moreIsOpen={moreIsOpen} clickHandlers={clickHandlers} />

      <div className="more-actions-content">{moreIsOpen && <Smiley />}</div>

      {sidebarIsOpen && <Sidebar handleCategoryClick={clickHandlers.sidebarCategory} />}

      {basketIsOpen && <Basket handleClick={clickHandlers.continue} />}
    </div>
  );
}
