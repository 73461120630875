import { useTranslation } from "react-i18next";
import { useBasketSelector } from "store/basket";

import OrderList from "components/basket/OrderList/OrderList";
import SimpleTotal from "components/basket/totals/SimpleTotal";

import "./FullBasket.scss";

interface IFullBasketProps {
  clickHandlers: {
    clearBasket: () => void;
    continue: () => void;
  };
}
export default function FullBasket(props: IFullBasketProps) {
  const { clickHandlers } = props;

  const { basketProducts, basketProductTotal } = useBasketSelector();
  const { t } = useTranslation();

  const handleClearBasketClick = () => {
    const confirmed = window.confirm(t("cart:ConfirmClearCartMessage"));
    if (confirmed) {
      clickHandlers.clearBasket();
    }
  };

  return (
    <div className="expandedcart">
      <div className="expandedcart-inner inner">
        <div className="expanded-cart-section cart-title-container">
          <h1 className="cart-title">{t("cart:CartTitle")}</h1>
          {basketProducts.length > 0 && (
            <p className="clear-cart" onClick={handleClearBasketClick}>
              {t("cart:Clear")}
            </p>
          )}
        </div>

        <div className="expanded-cart-section">
          <OrderList products={basketProducts} />
        </div>

        {basketProducts.length > 0 && (
          <div className="expanded-cart-section cart-summary">
            <SimpleTotal total={basketProductTotal} text={t("cart:YourTotal")} />
            <div className="cart-cta-button">
              <button className="button" onClick={clickHandlers.continue}>
                {t("cart:GoToCheckout")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
