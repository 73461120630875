import { ILayoutOptions } from "models/layout";
import Basket from "components/basket/Basket";
import LanguagePicker from "components/ui/language/LanguagePicker";
import Logo from "components/ui/Logo";
import { useAppSelector } from "store/app";
import More from "./More";

import "./Topbar.scss";

interface ITopbarProps extends Partial<ILayoutOptions> {
  moreIsOpen: boolean;
  clickHandlers: {
    sidebar: () => void;
    logo: () => void;
    basket: () => void;
    continue: () => void;
    more: () => void;
  };
}

export default function Topbar(props: ITopbarProps) {
  const { hideSidebar, hideBasket, moreIsOpen, clickHandlers } = props;

  const { appSettings } = useAppSelector();

  return (
    <header className="header inner">
      <div className="header-section mobile-sidebar-trigger">
        {!hideSidebar && (
          <button className="mobile-sidebar-button" onClick={clickHandlers.sidebar}>
            ☰
          </button>
        )}
      </div>

      <div className="header-section">
        <Logo handleClick={clickHandlers.logo} />
      </div>

      <div className="header-section minicart-section">
        {appSettings.smileyUrl && <More handleClick={clickHandlers.more} isOpen={moreIsOpen} />}

        <LanguagePicker />

        {!hideBasket && <Basket isMini handleClick={clickHandlers.basket} />}
      </div>
    </header>
  );
}
