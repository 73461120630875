import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import appReducer from "./app";
import basketReducer from "./basket";
import menuReducer from "./menu";
import checkoutReducer from "./checkout";

export const store = configureStore({
  reducer: {
    app: appReducer,
    basket: basketReducer,
    menu: menuReducer,
    checkout: checkoutReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
