import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { da } from "./languages/da";
import { de } from "./languages/de";
import { en } from "./languages/en";

const resources = {
  "da-DK": da,
  "de-DE": de,
  "en-US": en,
};

i18n.use(initReactI18next).init({
  resources,

  lng: "da-DK",
  fallbackLng: "da-DK",

  ns: ["cart", "common", "errors", "pagetitles", "precheckout", "products", "checkout", "confirmation"],
  defaultNS: "common",

  supportedLngs: ["da-DK", "de-DE", "en-US"],
  load: "currentOnly",

  react: {
    useSuspense: true,
  },
});

export default i18n;
