import { ICriticalError } from "@crunchit/types";

const isProduction = process.env.NODE_ENV === "production";

export function browserIsOutdated() {
  let ua = window.navigator.userAgent;
  let msie = ua.indexOf("MSIE ");

  // eslint-disable-next-line
  const IE = msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

  return IE;
}

export function customInsightsEvent(eventName: string, data: { [key: string]: string }) {
  if (window.appInsights) {
    try {
      window.appInsights.trackEvent({ name: eventName, properties: data });
    } catch (err) {
      logError(err);
    }
  }
}

export const logError = (error: any) => {
  if (!isProduction) {
    console.log(error);
    console.trace(error);
  }
};

export const logICriticalError = (appError: ICriticalError) => {
  if (!isProduction) {
    console.error(appError);
  }
};
