import { IBasketChoiceToAdd, IBlockedChoice, IChoice, IContext, IModifier, IProduct, IProductModifier, ISelectedChoice, ISelectedChoices } from "@crunchit/types";
import { filterModifiersForSoldOutChoices, getTotalChoiceCount, hasReachedMaxChoices, hasReachedMinChoices } from "@crunchit/utilities";
import { useCallback, useState } from "react";

import useTranslationText from "hooks/useTranslationText";
import { basketThunks, useBasketSelector } from "store/basket";
import { useCustomDispatch } from "store/useStore";
import { getTotalChoicePriceForModifier } from "utils/helpers/menu";

export default function useModifiers(product: IProduct, context: IContext) {
  const { basket } = useBasketSelector();
  const dispatch = useCustomDispatch();
  const { getTranslationText } = useTranslationText();

  let [selectedChoices, setSelectedChoices] = useState<ISelectedChoices>({});

  let [showFinalizeWarning, setShowFinalizeWarning] = useState(false);

  const getModifiers = useCallback(
    (blockedChoices: IBlockedChoice[]): IModifier[] => {
      const productModifierIds = product.modifiers.map((mod) => mod.modifierId); // This list has sortOrder and is already sorted so we use that order
      const blockedChoiceIds = blockedChoices.map((b) => b.choiceId);
      return filterModifiersForSoldOutChoices(context.modifiers, productModifierIds, blockedChoiceIds);
    },
    [product, context]
  );

  const getProductModifier = useCallback((modifierId: number) => product.modifiers.find((m: IProductModifier) => m.modifierId === modifierId), [product]);

  const updateChoiceCount = useCallback(
    (choiceId: number, modifier: IModifier, newCount: number) => {
      let continueToNextModifier = false;

      const choice = context.choices.find((c: IChoice) => c.id === choiceId);
      const productModifier = getProductModifier(modifier.id);

      if (choice && productModifier) {
        let updatedSelectedChoices = Object.assign({}, selectedChoices);
        let updatedSelectedChoicesList = updatedSelectedChoices[modifier.id] ? updatedSelectedChoices[modifier.id] : [];

        let existingChoiceIndex = updatedSelectedChoicesList.findIndex((c: ISelectedChoice) => c.choiceId === choice.id);
        if (existingChoiceIndex > -1) {
          // Existing choice being updated
          let updatedSelectedChoice = updatedSelectedChoicesList[existingChoiceIndex];
          updatedSelectedChoice.amount = newCount;
          updatedSelectedChoicesList[existingChoiceIndex] = updatedSelectedChoice;
        } else {
          // New choice
          const name = getTranslationText(choice.name);
          let newSelectedChoice = { choiceId: choice.id, name, amount: newCount };
          updatedSelectedChoicesList.push(newSelectedChoice);
        }

        // Finally, updating the whole selected modifier object
        updatedSelectedChoices[modifier.id] = updatedSelectedChoicesList;
        setSelectedChoices(updatedSelectedChoices);

        const updatedTotalChoiceCount = updatedSelectedChoicesList.reduce((total: number, current: ISelectedChoice) => total + current.amount, 0);
        if (hasReachedMaxChoices(updatedTotalChoiceCount, productModifier.maxChoices)) {
          continueToNextModifier = true;
        }
      }
      return continueToNextModifier;
    },
    [context, getProductModifier, selectedChoices, getTranslationText]
  );

  const getTotalPriceForModifiers = useCallback(
    (modifiers: IModifier[]) => {
      let price = product.price;
      for (let key in selectedChoices) {
        if (selectedChoices.hasOwnProperty(key)) {
          const modifierId = Number(key);
          const modifier = modifiers.find((m) => m.id === modifierId);
          const productModifier = getProductModifier(modifierId);

          if (modifier && productModifier) {
            const choicePrice = getTotalChoicePriceForModifier(modifier, productModifier?.choicesIncluded, context.choices, selectedChoices[Number(key)]);
            price += choicePrice;
          }
        }
      }
      return price;
    },
    [product, context, selectedChoices, getProductModifier]
  );

  const modifierHasWarning = useCallback(
    (modifier: IModifier) => {
      let warning = true;

      const productModifier = getProductModifier(modifier.id);
      if (productModifier) {
        const selected = selectedChoices[modifier.id];
        if (!selected) {
          if (productModifier.minChoices === 0) {
            warning = false;
          }
        } else {
          const choiceCount = getTotalChoiceCount(selected);
          if (hasReachedMinChoices(choiceCount, productModifier.minChoices)) {
            warning = false;
          }
        }
      }
      return warning;
    },
    [selectedChoices, getProductModifier]
  );

  const finalize = (modifiers: IModifier[]) => {
    let success = false;

    // Checking whether there is a warning to show - true unless verified otherwise
    const checkForWarning = (modifiers: IModifier[]) => {
      const modifierWarnings = modifiers.map((m) => modifierHasWarning(m));
      return modifierWarnings.indexOf(true) > -1;
    };

    const hasFinalizeWarning = checkForWarning(modifiers);
    if (hasFinalizeWarning) {
      setShowFinalizeWarning(true);
    } else {
      setShowFinalizeWarning(false);

      let choicesToAdd: IBasketChoiceToAdd[] = [];

      const addChoices = (modifierId: number, selectedChoiceList: ISelectedChoice[]) => {
        const mappedChoices = selectedChoiceList
          .map<IBasketChoiceToAdd>((sChoice: ISelectedChoice) => {
            return { ...sChoice, modifierId };
          })
          .filter((c) => c.amount > 0);

        choicesToAdd = choicesToAdd.concat(mappedChoices);
      };

      for (let key in selectedChoices) {
        if (selectedChoices.hasOwnProperty(key)) {
          const modifierId = Number(key);
          const selectedChoiceList = selectedChoices[key];
          addChoices(modifierId, selectedChoiceList);
        }
      }

      // Items with modifiers are always added as new, not updated with amount
      dispatch(basketThunks.addBasketItem({ basketId: basket.id, product, count: 1, choices: choicesToAdd }));

      success = true;
    }

    return success;
  };

  function reset() {
    setSelectedChoices({});
  }

  return {
    getModifiers,
    getProductModifier,
    selectedChoices,
    updateChoiceCount,
    modifierHasWarning,
    getTotalPriceForModifiers,
    finalize,
    showFinalizeWarning,
    reset,
  };
}
