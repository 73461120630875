import { useAppSelector } from "store/app";

import "./FasttrackIndicator.scss";

export default function FasttrackIndicator() {
  const { isFasttrack } = useAppSelector();

  if (!isFasttrack) {
    return <></>;
  }

  return (
    <div className="fasttrack-indicator">
      <p className="fasttrack-indicator-text">Fasttrack</p>
    </div>
  );
}
