import { useTranslation } from "react-i18next";
import { ISessionItem } from "@crunchit/types";

import { useBasketSelector } from "store/basket";
import useBasketUpdates from "hooks/useBasketUpdates";

import OrderListItem from "./OrderListItem";
import "./OrderList.scss";

interface IOrderListProps {
  products: ISessionItem[];
  isFinal?: boolean; // Indicates if we'er allowed to edit the list
}

export default function OrderList(props: IOrderListProps) {
  const { products, isFinal } = props;

  const { t } = useTranslation();
  const { isLoading: basketIsLoading } = useBasketSelector();
  const { handleBasketChange } = useBasketUpdates();

  function itemCountChanged(productId: number, newCount: number) {
    if (!isFinal) {
      handleBasketChange({ newCount, productId });
    }
  }

  return (
    <>
      {products.length > 0 ? (
        <div className={`orderlist ${isFinal ? "disabled" : ""}`}>
          {products.map(function (product, index) {
            return <OrderListItem key={isFinal ? index : product.id} product={product} hideEditOptions={isFinal} disableEditOptions={basketIsLoading} itemCountChanged={itemCountChanged} />;
          })}
        </div>
      ) : (
        <div className="empty-orderlist">
          <p className="text">{t("cart:EmptyCart")}</p>
        </div>
      )}
    </>
  );
}
