import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

import "./Terms.scss";

interface ITermsProps {
  checked: boolean;
  updateChecked: (updatedValue: boolean) => void;
}

export default function Terms(props: ITermsProps) {
  const { checked, updateChecked } = props;

  const { appSettings } = useAppSelector();
  const { t } = useTranslation();

  return (
    <div className="terms-checkbox">
      <label>
        <div className="checkbox-container">
          <input type="checkbox" className="checkbox" checked={checked} onChange={() => updateChecked(!checked)} />
        </div>

        <span className="checkbox-label">
          {t("checkout:Payment.TermsText")}{" "}
          <a href={appSettings.terms} target="_blank" rel="noopener noreferrer">
            {t("checkout:Payment.TermsTextLink")}
          </a>
        </span>
      </label>
    </div>
  );
}
