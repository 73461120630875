import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import PageTitle from "components/ui/PageTitle";
import CategoryGrid from "components/ui/CategoryGrid";

export default function Home() {
  const { t } = useTranslation();
  const { appSettings, isRoomService } = useAppSelector();
  const { categories } = useMenuSelector();
  const redirect = useNavigate();

  const handleCategoryClick = (categoryId: number) => redirect(`/menu/${categoryId}`);

  return (
    <>
      {appSettings.showClosedFrontpageMessage ? (
        <div className="content-body closed-frontpage-message">
          <PageTitle title={t("pagetitles:ClosedFrontpage.Title")} subtitle={t("pagetitles:ClosedFrontpage.Subtitle")} />
        </div>
      ) : (
        <div className="content-body">
          <PageTitle title={t(`pagetitles:Frontpage.${isRoomService ? "RoomService" : ""}Title`)} />

          <CategoryGrid categories={categories} handleCategoryClick={handleCategoryClick} />
        </div>
      )}
    </>
  );
}
